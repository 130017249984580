import { makeAutoObservable } from 'mobx';

class StoreEasyShop {
    isEnabled = false
    isEasyShopForm = false
    easyChoice = 2
    baID = ''
    referralCode = ''
    referralBy = ''
    referralValue = ''
    referralText = ''
    referenceDetail = {}
    referralAddress = {
        delivery: {
           
        },
        pickUp: {
           
        },
        aramex: {
            
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeEasyShop = new StoreEasyShop()