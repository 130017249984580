import { makeAutoObservable, observable, entries, values, get as mGet, set as mSet, remove as mRemove } from 'mobx';
import { isNothing, isSomething, isString, isNumber } from '../components/utils/Utils';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import { getPrice } from '../components/shopping/ProductPrice';

export const mapProductSource = observable.map()

class StoreProduct {
    sortDirection = 'asc'
    isInitialized = false
    starterKits = []
    renewSet = []
    category = null
    hot = null
    top10 = null
    cart = {}
    country = {}
    limitedQty = {
        min: 0,
        max: 999
    }
    shop = []
    // * Products Management
    get productsList() {
        const list = []
        const map = mapProductSource.entries()
        for (let i = 0; i < mapProductSource.size; i++) {
            list.push(map.next().value)
        }
        return list
    }
    get products() {
        return mapProductSource
    }
    get productsKeys() {
        return mapProductSource.keys()
    }
    get productsValues() {
        return mapProductSource.values()
    }
    get productsEntries() {
        return mapProductSource.entries()
    }
    getProduct(id) {
        return mapProductSource.get(id)
    }
    setProduct(id, value) {
        return mapProductSource.set(id, value)
    }
    removeProduct(id) {
        return mapProductSource.delete(id)
    }
    hasProduct(id) {
        return mapProductSource.has(id)
    }
    get cartList() {
        const list = []
        const map = entries(this.cart)
        map.map(([key, qty]) => {
            list.push({ id: key, qty: parseInt(qty) })
        })
        return list
    }
    get cartValues() {
        return values(this.cart)
    }
    get cartEntries() {
        return entries(this.cart)
    }
    // * -------------------
    getCartTotal() {
        let total = 0
        entries(this.cart).map(([key, qty]) => {
            total += getPrice(key) * qty
        })
        return total
    }
    getCartTotalPV() {
        let totalPV = 0
        entries(this.cart).map(([key, qty]) => {
            if (this.getProduct(key)) {
                const pv = this.getProduct(key).pv
                if (pv) {
                    totalPV += parseInt(pv) * qty
                }
            }
        })
        return totalPV
    }
    getCartItemTotal(key) {
        return getPrice(key) * this.getCartItemQty(key)
    }
    getCartItemTotalPV(key) {
        return this.getCartItem(key).pv * this.getCartItemQty(key)
    }
    isCartEmpty() {
        return isNothing(this.cart)
    }
    // clear
    clearCart() {
        this.cart = {}
    }
    getCartItem(itemCode) {
        return mGet(this.cart, itemCode)
    }
    getCartItemQty(itemCode) {
        if (this.isCartEmpty()) return 0
        const qty = mGet(this.cart, itemCode)
        if (isSomething(qty)) {
            return parseInt(qty)
        } else {
            return 0
        }
    }
    // add
    addToCart(itemCode, qty = 1) {
        // limited min qty
        const currentQty = this.getCartItemQty(itemCode)
        let newQty = currentQty + qty
        if (newQty >= this.limitedQty.max) {
            newQty = this.limitedQty.max
        }
        mSet(this.cart, { [itemCode]: newQty })
    }
    // remove
    removeFromCart(itemCode, qty = 1) {
        // limited min qty
        const currentQty = this.getCartItemQty(itemCode)
        let newQty = currentQty - qty
        if (newQty <= this.limitedQty.min) {
            newQty = this.limitedQty.min
        }
        if (newQty > this.limitedQty.min) {
            mSet(this.cart, { [itemCode]: newQty })
        } else {
            mRemove(this.cart, itemCode)
        }
    }
    // direct change
    assignToCart(itemCode, qty) {
        let newQty = qty
        let acceptedItemCode = itemCode
        if (isNumber(itemCode)) {
            acceptedItemCode = `${itemCode}`
        }
        if (isString(acceptedItemCode)) {
             // limited qty
            if (newQty >= this.limitedQty.max) {
                newQty = this.limitedQty.max
            }
            if (newQty <= this.limitedQty.min) {
                newQty = this.limitedQty.min
            }
            if (newQty <= 0) {
                mRemove(this.cart, itemCode)
            } else {
                mSet(this.cart, { [itemCode]: newQty })
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeProduct = new StoreProduct()