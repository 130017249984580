import { isSomething } from '@Utils/Utils'
import { makeAutoObservable } from 'mobx'

class StoreAPI{
    orderCalc = {
        isLoading: false,
        isResponsed: false,
        /** Whenever orderCalc completed it's request, set this `true`. 
         * Always reset to `false` when mount **OrderSummary** page. */
        isPassedResponse: false,

        /** A reponsed data from orderCalc after it's done calculated. */
        orderTerms: {},
        orderTermsJson: {},

        type: '', // 'error', 'warning'
        messages: [],
        promotions: [],
        freeItems: {},
        triggers: [],
        disallows: [],
        disallowInfo: {},
        hasError: false,
        hasMockError: false,
        configs: {
            isRetryOnAPIFail: true,
            maxRetryAttempt: 2,
            currentRetryAttempt: 0
        },

        get isTypeWarning() {
            return this.type === 'warning'
        },
        get isTypeError() {
            return this.type === 'error'
        },
        get isWarning() {
            return this.hasError && this.type === 'warning'
        },
        get isError() {
            return this.hasError && this.type === 'error'
        },

        setDisallows(disallows) {
            this.disallows = disallows
        },

        resetDisallows() {
            this.disallows = []
        },

        setDisallowInfo(info) {
            this.disallowInfo = info
        },

        resetDisallowInfo() {
            this.disallowInfo = {}
        },

        // free items from ordercalc
        setFreeItems(items) {
            this.freeItems = items
        },

        resetFreeItems() {
            this.freeItems = {}
        },
        setTriggers(triggers) {
            this.triggers = triggers
        },        

        get isContainFreeItems() {
            return isSomething(this.freeItems)
        },

        // promotions
        setPromotions(items) {
            this.promotions = items
        },

        resetPromotions() {
            this.promotions = []
        },

        get isContainPromotion() {
            return this.promotions && this.promotions.length > 0
        },
    }
    constructor() {
        makeAutoObservable(this)
    }
}

export const storeAPI = new StoreAPI()