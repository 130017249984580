import { apiURL, getCurrentDomain, getHydraNotesUshopLink, createDuplicatedError, isDuplicatedError } from '@Components/ShoppingHelper'
import { translateHydraErrors } from '@Components/utils/Errors'
import { getQueryString } from '@Configs/ConfigsHeader'
import { numeralFormat } from '@GlobalHelpers'
import { language } from '@Language'
import Raven from '@Raven'
import { calculateOrder, cartLimit, generateEnrollID, getEnroll, splashPayWithCredit, updateEnroll, validateCart } from '@Services/Network'
import { lowerCase } from '@Utils/String'
import { get } from 'lodash'
import { store } from './MainStore'
import { Country } from './StoreConfigCountry'
import StoreCountry from './StoreCountry'

const { makeAutoObservable, autorun } = require('mobx')

const padZerosToLength = (value, minLength, padChar) => {
    var iValLength = value.toString().length
    return new Array(minLength + 1 - iValLength).join(padChar) + value
}

const TYPE_ASSOCIATE = 'Associate'
const TYPE_PREFERRED = 'PreferredCustomer'
const TYPE_MEMBER = 'Member'

const SUBSCRIBE_ONETIME_PURCHASE = 'one-time-purchase'
const SUBSCRIBE_MONTHLY_SUBSCRIPTION = 'monthly-subscription'
class StoreSplash {
    IsSplashReady = false
    stateReferrenceId = null

    checkoutCart = []
    productSourcePreferred = []
    productSourceWholesale = []
    productSourceMember = []
    productSourceFeelGreatPreferred = []
    productSourceFeelGreatWholesale = []
    productSourceFeelGreatMember = []
    selectedItems = []
    splashKitItems = []
    warehouse = ''
    splashData = null

    additionalProducts = 0

    useSleepy = false

    Period = ''

    CreditCard = {
        cardName: 'sdf asfd',
        cardNumber: '4111111111111111',
        cardMonth: '01',
        cardYear: '2023',
        cardCVV: '123',
        validatePass: false
    }

    Validate = {
        isWarning: false,
        loading: false,
        isError: false,
        errorMessages: [],
        calculating: {
            Associate: false,
            PreferredCustomer: false,
            Member: false
        },
        calculatingAll: false,
        paymentLoading: false
    }

    customerType = TYPE_ASSOCIATE

    OrderTerms = {
        Associate: null,
        PreferredCustomer: null,
        Member: null
    }

    Steps = {
        isCheckedOut: false,
        isPaid: false,
        isCheckedTermAndTc: false
    }

    cart = []

    showPasswordMasking = [false, false]
    passwordMasking = ['', '']

    ShippingDetail = {
        information: [
            {
                key: 'firstname',
                title: 'splash_name',
                value: '',
                props: {
                    type: 'text'
                }
            }
        ],
        contact: [
            {
                key: 'address',
                title: 'splash_address',
                value: '',
                props: {
                    type: 'text'
                }
            },
            {
                key: 'city',
                title: 'splash_city',
                value: '',
                props: {
                    type: 'text'
                }
            },
            {
                key: 'state',
                title: 'splash_state',
                value: '',
                props: {
                    type: 'text'
                }
            },
            {
                key: 'zipCode',
                title: 'splash_zipcode',
                value: '',
                props: {
                    type: 'number'
                }
            },
            {
                key: 'country',
                title: 'splash_country',
                value: Country.getFormal(true),
                props: {
                    disabled: true,
                    type: 'text'
                }
            },
            {
                key: 'mobile',
                title: 'splash_mobile_number',
                value: '',
                props: {
                    type: 'tel'
                }
            },
            {
                key: 'email',
                title: 'splash_email',
                value: '',
                props: {
                    type: 'email'
                }
            }
        ],
        password: [
            {
                key: 'password',
                title: 'splash_password',
                value: '',
                props: {
                    type: 'password'
                }
            },
            {
                key: 'passwordConfirm',
                title: 'splash_password_confirm',
                value: '',
                props: {
                    type: 'password'
                }
            }
        ]
    }

    TermsAgree = {
        sendEmail: true,
        agree: true
    }

    TotalItems = 0
    TotalPrice = 0

    IsNeedToOrder = false

    template = 'v1'

    currency = ''
    currentCountry = ''

    subsciption = SUBSCRIBE_MONTHLY_SUBSCRIPTION

    SubscriptionType() {
        return this.subsciption
    }

    SetSubscription(value = true) {
        this.subsciption = value ? SUBSCRIBE_MONTHLY_SUBSCRIPTION : SUBSCRIBE_ONETIME_PURCHASE
    }

    CreatePathUrl(page = '', newQueryString = {}) {
        let path = `${this.UrlRootPath()}/${this.PaymentType()}/${page}`
        let currentQueryString = new URLSearchParams(window.location.search)
        Object.keys(newQueryString).map(v => {
            if (!currentQueryString.has(v)) {
                currentQueryString.append(v, newQueryString[v])
            } else {
                currentQueryString.set(v, newQueryString[v])
            }
        })

        return `${path}?${currentQueryString.toString()}`
    }

    PaymentType() {
        return this.subsciption
    }

    ProductsReady() {
        if (this.IsTypeMember()) {
            return this.productSourceMember.length > 0 && this.productSourceFeelGreatMember.length > 0
        } else if (this.IsTypeAssociate()) {
            return this.productSourceWholesale.length > 0 && this.productSourceFeelGreatWholesale.length > 0
        } else {
            return this.productSourcePreferred.length > 0 && this.productSourceWholesale.length > 0 && this.productSourceFeelGreatPreferred.length > 0 && this.productSourceFeelGreatWholesale.length > 0
        }
    }

    ShowItemCode() {
        return this.template !== 'v3'
    }

    CurrentCountry() {
        return this.currentCountry
    }

    SetCurrentCountry(value) {
        this.currentCountry = value
    }

    Currency() {
        return this.currency
    }

    SetCurrency(value) {
        this.currency = value
    }

    UrlRootPath() {
        return `/${StoreCountry.CountryLowerCase()}/splash`
    }

    Template() {
        return this.template
    }

    SetTemplate(template) {
        this.template = template
    }

    HasMultipleSplashKit() {
        return this.Template() === 'v3' || this.Template() === 'v4'
    }

    InitStep(step) {
        if (step === undefined) {
            this.Steps.isCheckedOut = false
            this.Steps.isPaid = false
            this.Steps.isCheckedTermAndTc = false
        } else if (step === 'shipping-detail') {
            this.Steps.isCheckedOut = true
            this.Steps.isPaid = false
            this.Steps.isCheckedTermAndTc = false
        } else if (step === 'payment') {
            this.Steps.isCheckedOut = true
            this.Steps.isPaid = false
            this.Steps.isCheckedTermAndTc = true
        }
    }

    IsPaymentPage() {
        return this.Steps.isCheckedOut === true && this.Steps.isCheckedTermAndTc === true && this.Steps.isPaid === false
    }

    IsFirstPage() {
        return this.Steps.isCheckedOut === false && this.Steps.isCheckedTermAndTc === false && this.Steps.isPaid === false
    }

    IsShippingDetailPage() {
        return this.Steps.isCheckedOut === true && this.Steps.isCheckedTermAndTc === false && this.Steps.isPaid === false
    }

    GetError() {
        return this.Validate.errorMessages || []
    }

    ClearError() {
        this.Validate.isError = false
        this.Validate.errorMessages = []
        this.Validate.paymentLoading = false
    }

    Lines() {
        const products = []
        this.cart.map(item => {
            if (item.qty > 0) {
                products.push({
                    item: {
                        href: `https://hydra.unicity.net/v5a/items?id.unicity=${item.item_code}`
                    },
                    quantity: item.qty,
                    item_code: item.item_code,
                    href_params: [`id.unicity=${item.item_code}`]
                })
            }
        })
        return products
    }

    CustomerType() {
        if (this.IsStatusA()) return TYPE_ASSOCIATE
        if (this.IsStatusM()) return TYPE_MEMBER
        else return this.customerType
    }

    GetOrderTerms() {
        return get(this.OrderTerms[this.CustomerType()], 'items', [])
    }

    GetSubscribeOrderTermsPrice() {
        return this.GetOrderTerms()[0] ? get(this.GetOrderTerms()[0], 'terms.total', 0) : 0
    }

    GetSubscribeOrderTermsPv() {
        return this.GetOrderTerms()[0] ? get(this.GetOrderTerms()[0], 'terms.pv', 0) : 0
    }

    GetOrderTermsCurrency() {
        return this.GetOrderTerms()[0] ? get(this.GetOrderTerms()[0], 'currency', this.Currency()) : this.Currency()
    }

    GetOrderTermsShipping() {
        return this.GetOrderTerms()[0] ? get(this.GetOrderTerms()[0], 'terms.freight.amount', 0) : 0
    }

    CheckOut = customerType => {

        const letgitCustomerType = customerType || this.CustomerType()

        const checkoutItems = {
            loading: false,
            customerType: letgitCustomerType,
            data: {
                order: {
                    customer: {
                        href: `https://hydra.unicity.net/v5a/customers?type=${letgitCustomerType}`
                    },
                    lines: {
                        items: this.Lines()
                    },
                    notes: getHydraNotesUshopLink(''),
                    shipToAddress: {
                        address1: this.getShippingDetailValue('address', this.ShippingDetail.contact),
                        address2: '',
                        city: this.getShippingDetailValue('city', this.ShippingDetail.contact),
                        state: this.getShippingDetailValue('state', this.ShippingDetail.contact),
                        country: this.CurrentCountry(),
                        zip: this.getShippingDetailValue('zipCode', this.ShippingDetail.contact)
                    },
                    shippingMethod: {
                        href: 'https://hydra.unicity.net/v5a/shippingmethods?type=Expedited'
                    },
                    shipToEmail: this.getShippingDetailValue('email', this.ShippingDetail.contact),
                    shipToName: {
                        firstName: this.getShippingDetailValue('firstname', this.ShippingDetail.information),
                        lastName: ''
                    },
                    shipToPhone: this.getShippingDetailValue('mobile', this.ShippingDetail.contact)
                }
            },
            orderCalculated: {
                lines: {
                    items: []
                },
                terms: {
                    discount: {
                        amount: 0
                    },
                    freight: {
                        amount: 0
                    },
                    pv: 0,
                    subtotal: 0,
                    tax: {
                        amount: 0
                    },
                    taxableTotal: 0,
                    total: 0
                }
            }
        }

        if (this.CurrentCountry() === 'NZ') {
            checkoutItems.data.order.shipToName.lastName = this.getShippingDetailValue('lastname', this.ShippingDetail.information)
        }

        return checkoutItems
    }

    PrepareProductItems(items) {
        const handler = (carry, item) => {
            if (parseInt(item['qty']) >= 1 && item.qty !== '0') {
                const price = this.IsTypeAssociate() || this.IsTypeMember() ? item['price_wholesale'] : item['price']
                let itemPv = parseInt(item['pv']) || 0
                const product = {
                    item: {
                        href: 'https://hydra.unicity.net/v5a/items?id.unicity=' + item['item_code']
                    },
                    quantity: item['qty'],
                    item_code: item['item_code'],
                    href_params: ['id.unicity=' + item['item_code']],
                    item_total_pv: itemPv * parseInt(item['qty']),
                    price_per_unit: price,
                    item_subtotal: parseFloat(price) * parseInt(item['qty'])
                }
                carry.push(product)
            }
            return carry
        }
        return items.reduce(handler, [])
    }

    DataForValidateAddress() {
        const validateItems = {
            language: language.now(),
            source: `USHOP-LINK-${this.CurrentCountry()}-SPLASH`,
            firstName: this.getShippingDetailValue('firstname', this.ShippingDetail.information),
            lastName: '',
            address1: this.getShippingDetailValue('address', this.ShippingDetail.contact),
            address2: '',
            state: this.CheckOut().data.order.shipToAddress.state,
            city: this.getShippingDetailValue('city', this.ShippingDetail.contact),
            zip: this.getShippingDetailValue('zipCode', this.ShippingDetail.contact),
            country: StoreCountry.Country2(),
            email: this.getShippingDetailValue('email', this.ShippingDetail.contact),
            mobilePhone: this.getShippingDetailValue('mobile', this.ShippingDetail.contact),
            homePhone: '',
            password1: this.getShippingDetailValue('password', this.ShippingDetail.password),
            password2: this.getShippingDetailValue('passwordConfirm', this.ShippingDetail.password),
            period: this.Period
        }

        if (this.CurrentCountry() === 'NZ') {
            validateItems.lastName = this.getShippingDetailValue('lastname', this.ShippingDetail.information)
        }

        return validateItems
    }

    DataForValidatePayment() {
        this.Validate.loading = true

        const hydraData = () => {
            const objectData = {
                lines: this.CheckOut().data.order.lines,
                notes: this.CheckOut().data.order.notes,
                transactions: {
                    items: [
                        {
                            amount: this.GetSubscribeOrderTermsPrice(),
                            type: 'record',
                            method: 'CreditCard',
                            authorization: ''
                        }
                    ]
                },
                terms: {
                    period: this.Period
                },
                market: this.CurrentCountry(),
                shipToAddress: this.CheckOut().data.order.shipToAddress,
                shipToEmail: this.CheckOut().data.order.shipToEmail,
                shipToName: this.CheckOut().data.order.shipToName,
                shipToPhone: this.CheckOut().data.order.shipToPhone,
                shippingMethod: this.CheckOut().data.order.shippingMethod,
                customer: {
                    mainAddress: {
                        address1: this.CheckOut().data.order.shipToAddress.address1,
                        address2: '',
                        city: this.CheckOut().data.order.shipToAddress.city,
                        country: this.CurrentCountry(),
                        state: this.CheckOut().data.order.shipToAddress.state,
                        zip: this.CheckOut().data.order.shipToAddress.zip
                    },
                    humanName: {
                        firstName: this.CheckOut().data.order.shipToName.firstName,
                        lastName: this.CheckOut().data.order.shipToName.lastName
                    },
                    enroller: {
                        href: `https://hydra.unicity.net/v5a/customers?id.unicity=${this.getReferralId()}`,
                        href_params: [`id.unicity=${this.getReferralId()}`]
                    },
                    sponsor: {
                        href: `https://hydra.unicity.net/v5a/customers?id.unicity=${this.getReferralId()}`,
                        href_params: [`id.unicity=${this.getReferralId()}`]
                    },
                    password: {
                        value: this.ShippingDetail.password[0].value
                    },
                    email: this.CheckOut().data.order.shipToEmail,
                    homePhone: '',
                    mobilePhone: this.CheckOut().data.order.shipToPhone,
                    entryPeriod: this.Period,
                    type: this.IsStatusM() ? TYPE_MEMBER : TYPE_ASSOCIATE,
                    businessEntity: {
                        legalType: 'SoleProprietorship'
                    },
                    signature: {
                        value: 'TS'
                    }
                }
            }

            if (this.SubscriptionType() === SUBSCRIBE_MONTHLY_SUBSCRIPTION) {
                objectData.customer.autoorders = {
                    items: [
                        {
                            recurrence: {
                                dateStarts: '2021-03-15',
                                schedule: {
                                    month: '*/1',
                                    dayOfMonth: '15'
                                },
                                status: 'Enabled'
                            },
                            lines: this.CheckOut().data.order.lines,
                            shipToName: this.CheckOut().data.order.shipToName,
                            shipToPhone: this.CheckOut().data.order.shipToPhone,
                            shipToEmail: this.CheckOut().data.order.shipToEmail,
                            shipToAddress: this.CheckOut().data.order.shipToAddress,
                            shippingMethod: this.CheckOut().data.order.shippingMethod,
                            transactions: {
                                items: [
                                    {
                                        amount: this.GetSubscribeOrderTermsPrice(),
                                        type: 'record',
                                        method: 'CreditCard',
                                        authorization: ''
                                    }
                                ]
                            }
                        }
                    ]
                }
            }

            return objectData
        }

        return {
            language: language.now(),
            orderData: {
                skip_check_order_duplicated: this.IsNeedToOrder,
                baCountry: this.CurrentCountry(),
                enrollReferral: this.getReferralId(),
                items: this.PrepareProductItems(this.cart),
                minStockQty: store.minStockQty,
                referral_id: this.getReferralId(),
                enroller_id: this.getReferralId(),
                sponsor_id: this.getReferralId(),
                login_id: '',
                success_url: getCurrentDomain() + '/' + StoreCountry.CountryLowerCase() + '/splash/payment-response/success',
                error_url: getCurrentDomain() + '/' + StoreCountry.CountryLowerCase() + '/splash/payment-response/failed',
                emailConfirmation: this.TermsAgree.sendEmail ? 1 : 0,
                country: this.CurrentCountry(),
                source: `UNISHOP-EXPRESS-${StoreCountry.Country2()}`,
                market: 'SG',
                type: 'enroll',
                period: this.Period,
                comment: '',
                total_pv: this.GetSubscribeOrderTermsPv(),
                orderTerms: this.GetOrderTerms(),
                orderTermsJson: this.CheckOut().data,
                hydra: hydraData(),
                shippingMethod: 'delivery',
                mobile: this.CheckOut().data.order.shipToPhone,
                login_name: '',
                login_native_name: '',
                referral_name: this.getReferralName(),
                referral_text: '',
                enroller_name: this.getReferralName(),
                enroller_native_name: this.getReferralName(true),
                sponsor_name: this.getReferralName(),
                sponsor_native_name: this.getReferralName(true),
                password: hydraData().customer.password,
                language: language.now()
            }
        }
    }

    getShippingDetailValue(key, data) {
        const item = data.find(d => d.key === key)

        return item ? item.value : ''
    }

    SetShippingDetail(data, key, value) {
        const item = data.find(d => d.key === key)
        if (item) {
            if (item) {
                item.value = value
            }
        }
    }

    SetProductSource(items, status, type) {
        if (type === 'feelGreat') {
            if (status === 'P') {
                this.productSourceFeelGreatPreferred = typeof items === 'string' ? JSON.parse(items) : items
            } else if (status === 'A') {
                this.productSourceFeelGreatWholesale = typeof items === 'string' ? JSON.parse(items) : items
            } else if (status === 'M') {
                this.productSourceFeelGreatMember = typeof items === 'string' ? JSON.parse(items) : items
            }
        } else {
            if (status === 'P') {
                this.productSourcePreferred = typeof items === 'string' ? JSON.parse(items) : items
            } else if (status === 'A') {
                this.productSourceWholesale = typeof items === 'string' ? JSON.parse(items) : items
            } else if (status === 'M') {
                this.productSourceMember = typeof items === 'string' ? JSON.parse(items) : items
            }
        }
    }

    SelectProducts() {
        let temp = []

        if (this.productSourcePreferred) {
            this.productSourcePreferred.map((itemValue, itemKey) => {
                if (!itemValue.qty) {
                    itemValue.qty = 0
                }

                if (this.HasItemInCart(itemValue.item_code)) {
                    return false
                }

                if (/\-/.test(itemValue.item_code)) {
                    return false
                }

                let price = itemValue.price
                if (parseInt(price) === 0) {
                    return false
                }
                if (parseInt(itemValue.qty_sellable) === 0) {
                    return false
                }

                let title = itemValue.item_name.english
                if (language.isNative()) {
                    if (itemValue.item_name.native !== '') {
                        title = itemValue.item_name.native
                    }
                }

                let textSearch = itemValue.item_code + itemValue.item_name.native + itemValue.item_name.english

                title = padZerosToLength(itemValue.item_code, 5, 0) + ' ' + title

                temp.push({
                    value: itemValue.item_code,
                    text: textSearch,
                    html: '' + `${title}`
                })
            })

            temp.sort((a, b) => {
                const aName = a['text'].toLowerCase()
                const bName = b['text'].toLowerCase()
                return aName.localeCompare(bName)
            })
        }

        return temp
    }

    IsTypeAssociate() {
        return this.CustomerType() === TYPE_ASSOCIATE
    }

    IsTypePreferred() {
        return this.CustomerType() === TYPE_PREFERRED
    }

    IsTypeMember() {
        return this.CustomerType() === TYPE_MEMBER
    }

    setAdditionalProducts(value) {
        this.additionalProducts = value
    }

    SetSplashData(data) {
        if (data.products) {
            data.products = typeof data.products === 'string' ? JSON.parse(data.products) : data.products
        }
        this.splashData = data
        this.SetTemplate(data.template_ref)
        this.InitSplashKit(data.products)
    }

    get hasAdditionalProducts() {
        return this.additionalProducts > 0 || this.selectedItems.length > 1
    }

    SplashKitItems() {
        return this.splashKitItems || []
    }

    HasSplashKit() {
        return this.splashKitItems.length > 0 ? true : false
    }

    InitSplashKit(products) {
        const items = []
        products.map((v, k) => {
            const item = this.getProduct(v.item_code)
            if (item) {
                Object.keys(item).map(p => {
                    v[p] = item[p]
                    v.qty = this.HasMultipleSplashKit() ? 0 : 1
                    v.isSplashKit = true
                    this.SetItemInCart(v.item_code, this.HasMultipleSplashKit() ? 0 : 1, { isSplashKit: true })
                })
                items.push(v)
            }
        })

        this.splashKitItems = items
    }

    getProduct(itemCode) {
        let item = null
        if (this.IsTypeMember()) {
            item = this.productSourceFeelGreatMember.concat(this.productSourceMember).find(item => item.item_code === itemCode)
        } else if (this.IsTypeAssociate()) {
            item = this.productSourceFeelGreatWholesale.concat(this.productSourceWholesale).find(item => item.item_code === itemCode)
        } else {
            item = this.productSourceFeelGreatPreferred.concat(this.productSourcePreferred).find(item => item.item_code === itemCode)
        }
        return item
    }

    getReferralName(isNative = false) {
        const refNativeName = get(this.splashData, 'referral_native_name', null)
        const refName = get(this.splashData, 'referral_name', null)

        return isNative ? (refNativeName ? refNativeName : refName) : refName
    }

    getReferralNames() {
        const roman = get(this.splashData, 'referral_name', null)
        const native = get(this.splashData, 'referral_native_name', null)
        if (language.isEnglish()) {
            return `${roman}${native ? `, ${native}` : ''}`
        } else {
            return `${native ? `${native}, ` : ''}${roman}`
        }
    }

    getReferralId() {
        return get(this.splashData, 'referral_id', null)
    }

    GetCart() {
        if (this.cart.length === 0) {
            this.cart = this.SplashKitItems()
        }

        return this.cart || []
    }

    IsEmptyCart() {
        let cartData = []
        this.cart.map(e => {
            if (e.qty > 0) {
                return cartData.push({ ...e })
            }
        })
        return cartData.length > 0 ? false : true
    }

    TotalItemInCart() {
        return this.TotalItems
    }

    SetItemInCart(id, qty, optional = {}) {
        this.TotalItems = 0
        const NoItem = (id, qty) => {
            let itemPreferred = this.productSourceFeelGreatPreferred.concat(this.productSourcePreferred).find(i => i.item_code === id)
            let itemWholesale = this.productSourceFeelGreatWholesale.concat(this.productSourceWholesale).find(i => i.item_code === id)
            let itemMember = this.productSourceFeelGreatMember.concat(this.productSourceWholesale).find(i => i.item_code === id)
            if (this.IsStatusM() && itemMember) {
                itemMember.qty = qty
                Object.keys(optional).map(key => {
                    itemMember[key] = optional[key]
                })
                this.cart.push(itemMember)
            } else {
                if (itemPreferred) {
                    itemPreferred.qty = qty
                    Object.keys(optional).map(key => {
                        itemPreferred[key] = optional[key]
                    })
                    this.cart.push(itemPreferred)
                }
    
                if (itemWholesale) {
                    if (!itemPreferred) {
                        itemWholesale.qty = qty
                        Object.keys(optional).map(key => {
                            itemWholesale[key] = optional[key]
                        })
                        this.cart.push(itemWholesale)
                    }
    
                    this.cart.map((v, k) => {
                        if (v.item_code === itemWholesale.item_code) {
                            v.price_wholesale = itemWholesale.price
                        }
                        return v
                    })
                }
            }
        }

        const HasItem = (id, qty) => {
            let itemInCart = this.cart.find(i => i.item_code === id)
            if (itemInCart) {
                itemInCart.qty = qty
                Object.keys(optional).map(key => {
                    itemInCart[key] = optional[key]
                })
            } else {
                NoItem(id, qty)
            }
        }

        if (this.cart.length === 0) {
            NoItem(id, qty)
        } else {
            HasItem(id, qty)
        }

        let totalItems = 0
        this.cart.map((v, k) => {
            totalItems += v.qty
        })
        this.TotalItems = totalItems
    }

    GetItemInCart(id) {
        const item = this.cart.find(i => i.item_code === id)
        return item ? item : null
    }

    RemoveItemInCart(id) {
        const pos = this.cart.findIndex(el => el.item_code === id)
        if (pos >= 0) {
            this.cart.splice(pos, 1)
            this.TotalItems = 0
            this.cart.map((v, k) => {
                this.TotalItems += v.qty
            })
        }
    }

    HasItemInCart(id) {
        return this.GetItemInCart(id) ? true : false
    }

    ClearOrderTerms() {
        this.OrderTerms.Associate = null
        this.OrderTerms.PreferredCustomer = null
        this.OrderTerms.Member = null
        this.Steps.isCheckedTermAndTc = false
        this.IsNeedToOrder = false
    }

    CalculateOrder() {
        this.Validate.loading = true
        
        calculateOrder((response, success) => {
            if (success) {
                this.OrderTerms[this.CustomerType()] = response
            } else {
                this.Validate.isError = true
                this.Validate.errorMessages = ['Something went wrong']
            }

            this.Validate.loading = false
            this.Validate.calculatingAll = false
        }, JSON.stringify(this.CheckOut(this.CustomerType()).data))
    }

    GetShippingPrice(showCurrency = true) {
        let price = this.GetOrderTermsShipping()
        let currency = this.GetOrderTermsCurrency()

        return showCurrency ? `${price} ${currency}` : price
    }

    GetTotalPrice(price = 'default') {
        let totalPrice = 0
        if (price === 'default') {
            this.cart.map(v => {
                if (this.IsTypeMember()) {
                    totalPrice += v.price * v.qty
                } else if (this.IsTypeAssociate()) {
                    totalPrice += v.price_wholesale * v.qty
                } else {
                    totalPrice += v.price * v.qty
                }
            })
        } else if (price === 'orderTerms') {
            totalPrice = this.GetSubscribeOrderTermsPrice() + ' ' + this.GetOrderTermsCurrency()
        } else {
            this.cart.map(v => {
                totalPrice += v.price * v.qty
            })
        }

        return numeralFormat(totalPrice)
    }

    GetItemPrice(itemCode) {
        let item = null

        if (this.cart.length > 0) {
            item = this.cart.find(i => i.item_code === itemCode)
        } else {
            if (this.IsTypeMember()) {
                item = this.productSourceFeelGreatMember.concat(this.productSourceMember).find(i => i.item_code === itemCode)
            } else if (this.IsTypeAssociate()) {
                item = this.productSourceFeelGreatWholesale.concat(this.productSourceWholesale).find(i => i.item_code === itemCode)
            } else {
                item = this.productSourceFeelGreatPreferred.concat(this.productSourcePreferred).find(i => i.item_code === itemCode)
            }
        }

        return item ? numeralFormat(item.price) : 0
    }

    SetCustomerType(type) {
        this.customerType = type
    }

    IsSubscribeMonthly() {
        return this.SubscriptionType() === SUBSCRIBE_MONTHLY_SUBSCRIPTION
    }

    SetPassword(key, value) {
        let result = ''
        for (var i = 0; i < value.length; i++) {
            result += '*'
        }

        let lastChar = ''

        if (/\*/.test(value)) {
            lastChar = value[value.length - 1]
        } else {
            lastChar = value
        }

        let passwordValue = ''

        if (lastChar !== '*' && lastChar !== undefined) {
            if (/\*/.test(value)) {
                passwordValue = this.ShippingDetail.password[key].value + lastChar
            } else {
                passwordValue = lastChar
            }
        } else {
            passwordValue = this.ShippingDetail.password[key].value.slice(0, -1)
        }

        this.ShippingDetail.password[key].value = passwordValue
        this.passwordMasking[key] = result
    }

    GetPassword(index) {
        if (this.showPasswordMasking[index] === true) {
            return this.ShippingDetail.password[index].value
        } else {
            return this.passwordMasking[index]
        }
    }

    SetShowMasking(index) {
        if (this.showPasswordMasking[index] === true) {
            return (this.showPasswordMasking[index] = false)
        } else {
            return (this.showPasswordMasking[index] = true)
        }
    }

    ValidateSplash() {
        this.Validate.loading = true
        return new Promise((resolve, reject) => {
            Raven.getValidateSplash(this.DataForValidateAddress())
                .then(response => {
                    if (response.success === 'no') {
                        let msg = []
                        response.Message.map(v => {
                            msg.push({
                                english: v,
                                native: v
                            })
                        })

                        this.Validate.loading = false
                        this.Validate.isError = true
                        this.Validate.errorMessages = msg
                    } else {
                        this.ClearError()
                        this.CalculateOrder(TYPE_ASSOCIATE)
                        this.SaveState('contact')
                        resolve(true)
                    }
                })
                .catch(error => {
                    this.Validate.isError = true
                    this.Validate.errorMessages = ['Something went wrong']
                    this.Validate.loading = false
                })
        })
    }

    SaveState(updateState) {
        this.Validate.loading = true

        return new Promise((resolve, reject) => {
            let dataCartLimit = {
                countryCode3: `X${StoreCountry.Country2()}`,
                orderTermsJson: {
                    order: {
                        market: StoreCountry.Country2(),
                        customer: {
                            href: `https://hydra.unicity.net/v5a/customers?type=${this.CustomerType()}`
                        },
                        lines: {
                            items: this.Lines()
                        },
                        notes: this.CheckOut().data.order.notes,
                        shipToAddress: this.CheckOut().data.order.shipToAddress,
                        shippingMethod: {
                            href: 'https://hydra.unicity.net/v5/shippingmethods?type=Expedited'
                        }
                    }
                }
            }
            cartLimit((res, status) => {
                if (status) {
                    this.Validate.isWarning = false
                    this.ClearError()
                    let data = {
                        step_order_details: {
                            customerType: this.CheckOut().customerType,
                            data: this.CheckOut().data,
                            termsAgree: this.TermsAgree
                        }
                    }

                    data.step_order_details = JSON.stringify(data.step_order_details)

                    if (this.stateReferrenceId) {
                        updateEnroll(
                            (res, status) => {
                                this.Validate.loading = false
                                if (status) {
                                    this.stateReferrenceId = res.data.reference_id
                                    resolve({
                                        state: updateState,
                                        refId: res.data.reference_id
                                    })
                                } else {
                                    reject('error')
                                }
                            },
                            this.stateReferrenceId,
                            data
                        )
                    } else {
                        generateEnrollID((res, status) => {
                            if (status) {
                                this.Validate.loading = false
                                if (res.success === true) {
                                    this.stateReferrenceId = res.data.reference_id
                                    resolve({
                                        state: updateState,
                                        refId: res.data.reference_id
                                    })
                                } else {
                                    reject()
                                }
                            } else {
                                reject()
                            }
                        }, data)
                    }
                } else {
                    let responseText = JSON.parse(res.responseText)
                    this.Validate.isError = false
                    this.Validate.isWarning = true
                    this.Validate.errorMessages = responseText.error_messages
                    this.Validate.loading = false
                }
            }, dataCartLimit)
        })
    }

    GetEnrollState(stateRefId) {
        StoreSplash.stateReferrenceId = stateRefId
        return new Promise((resolve, reject) => {
            getEnroll((res, succ) => {
                if (succ) {
                    if (res.success === true) {
                        const data = res.data
                        this.stateReferrenceId = data.reference_id

                        if (data.step_order_details !== null) {
                            const stepOrderDetails = JSON.parse(data.step_order_details)
                            if (stepOrderDetails) {
                                const dataOrder = stepOrderDetails.data.order

                                console.log('stepOrderDetails.customerType', stepOrderDetails.customerType)

                                if (dataOrder) {
                                    this.SetCustomerType(stepOrderDetails.customerType || TYPE_ASSOCIATE)
                                    this.SetShippingDetail(this.ShippingDetail.contact, 'address', dataOrder.shipToAddress.address1)
                                    this.SetShippingDetail(this.ShippingDetail.contact, 'city', dataOrder.shipToAddress.city)
                                    this.SetShippingDetail(this.ShippingDetail.contact, 'state', dataOrder.shipToAddress.state)
                                    this.SetShippingDetail(this.ShippingDetail.contact, 'zipCode', dataOrder.shipToAddress.zip)

                                    this.SetShippingDetail(this.ShippingDetail.contact, 'email', dataOrder.shipToEmail)
                                    this.SetShippingDetail(this.ShippingDetail.contact, 'mobile', dataOrder.shipToPhone)

                                    this.SetShippingDetail(this.ShippingDetail.information, 'firstname', dataOrder.shipToName.firstName)
                                    this.SetShippingDetail(this.ShippingDetail.information, 'lastname', dataOrder.shipToName.lastName)

                                    this.TermsAgree = stepOrderDetails.termsAgree

                                    if (dataOrder.lines.items) {
                                        dataOrder.lines.items.map((v, k) => {
                                            const product = this.getProduct(v.item_code)
                                            if (product) {
                                                let itemInCart = this.cart.find(item => item.item_code === product.item_code)
                                                if (itemInCart) {
                                                    itemInCart.qty = v.quantity
                                                } else {
                                                    this.SetItemInCart(product.item_code, v.quantity)
                                                }
                                            }
                                        })
                                    }
                                }

                                this.Steps.isCheckedOut = false
                                this.Steps.isCheckedTermAndTc = false

                                resolve(true)
                            }
                        }
                    }

                    reject(false)
                }
            }, stateRefId)
        })
    }

    SubmitPayment() {
        this.Validate.paymentLoading = true

        return new Promise((resolve, reject) => {
            this.Payment()
                .then(response => {
                    this.ClearError()
                    resolve(response)
                })
                .catch(error => {
                    if (error.wantDuplicatedOrder) {
                        this.Validate.isError = false
                        this.Validate.isWarning = true
                        this.Validate.errorMessages = error.errorMessage
                        this.Validate.loading = false
                        this.IsNeedToOrder = true
                    } else {
                        this.Validate.isError = true
                        this.Validate.isWarning = false
                        this.Validate.errorMessages = error.errorMessage
                        this.Validate.loading = false
                    }
                    this.Validate.paymentLoading = false
                    reject(error)
                })
        })
    }

    Payment() {
        return new Promise((resolve, reject) => {
            validateCart(
                (res, succ) => {
                    if (succ === false) {
                        try {
                            let responseText = JSON.parse(res.responseText)
                            if (res.status === 400) {
                                if (isDuplicatedError(responseText.errors)) {
                                    reject({
                                        errorMessage: [createDuplicatedError(responseText)],
                                        errorPayment: false,
                                        wantDuplicatedOrder: true
                                    })
                                } else {
                                    reject({
                                        errorMessage: translateHydraErrors(responseText),
                                        errorPayment: false
                                    })
                                }
                            } else if (res.status === 422) {
                                reject({
                                    errorMessage: translateHydraErrors(responseText),
                                    errorPayment: false
                                })
                            } else if (res.status === 500) {
                                reject({
                                    errorMessage: [responseText.errors],
                                    errorPayment: false
                                })
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        console.log('DO PAYMENT ENCRYPT')

                        /* const encryptedObject = window.encrypt(JSON.stringify({
                        creditCardNumber: this.CreditCard.cardNumber,
                        creditCardSecurityCode: this.CreditCard.cardCVV
                    })) */

                        let dataPayment = {
                            reference_id: res.referenceId,
                            transactions: {
                                items: [
                                    {
                                        amount: this.GetSubscribeOrderTermsPrice(),
                                        type: 'AuthorizeAndCapture',
                                        method: 'CreditCard', //'CreditCardEncrypted',
                                        methodDetails: {
                                            /* creditCardEncrypted: {
                                        encryptedObject: encryptedObject
                                    }, */
                                            creditCardNumber: this.CreditCard.cardNumber,
                                            payer: this.CreditCard.cardName,
                                            creditCardExpires: `${this.CreditCard.cardYear}-${this.CreditCard.cardMonth}`,
                                            creditCardSecurityCode: this.CreditCard.cardCVV
                                        }
                                        /* billToAddress: {
                                    country: Country.getCode2()
                                } */
                                    }
                                ]
                            }
                        }

                        splashPayWithCredit((resP, succP) => {
                            try {
                                if (succP === false) {
                                    this.SaveState('payment', this.stateReferrenceId)

                                    let responseText = JSON.parse(resP.responseText)
                                    reject({
                                        errorMessage: translateHydraErrors(responseText),
                                        errorPayment: true,
                                        reference_id: res.referenceId
                                    })
                                } else {
                                    resolve({
                                        errorPayment: false,
                                        new_id: resP.new_id,
                                        order_id: resP.order_id,
                                        reference_id: resP.reference_id
                                    })
                                }
                            } catch (errorP) {
                                reject({
                                    errorMessage: JSON.stringify(errorP)
                                })
                            }
                        }, JSON.stringify(dataPayment))
                    }
                },
                this.DataForValidatePayment(),
                apiURL.shopping.validate_cart_api
            )
        })
    }

    getCustomerStatus() {
        return lowerCase(getQueryString().ba_status)
    }

    IsStatusA() {
        return this.getCustomerStatus() === 'a'
    }

    IsStatusM() {
        return this.getCustomerStatus() === 'm'
    }

    constructor() {
        makeAutoObservable(this)

        autorun(r => {
            if (StoreCountry.Country3()) {
                if (StoreCountry.Country3() === 'NZL') {
                    this.ShippingDetail.information.push({
                        key: 'lastname',
                        title: 'splash_lastname',
                        value: '',
                        props: {
                            type: 'text'
                        }
                    })

                    this.ShippingDetail.contact.map(v => {
                        if (v.key === 'country') {
                            v.value = StoreCountry.CountryCapitalize()
                        }

                        if (v.key === 'firstname') {
                            v.title = 'splash_firstname'
                        }

                        return v
                    })

                    this.ShippingDetail.information.map(v => {
                        if (v.key === 'firstname') {
                            v.title = 'splash_firstname'
                        }

                        return v
                    })

                    this.SetCurrency('NZD')
                    this.SetCurrentCountry('NZ')
                } else if (StoreCountry.Country3() === 'AUS') {
                    this.SetCurrency('AUD')
                    this.SetCurrentCountry('AU')
                }

                r.dispose()
            }
        })
    }
}

export default new StoreSplash()
