import { makeAutoObservable } from 'mobx';
import * as $ from './APIs';
import { getOrderHistoryList, getOrderHistoryCurrency, getOrderHistoryDetails, getOrderHistoryByBAID } from './APIs/APIOrderHistory';
import { getProductSource } from './APIs/APIProductSource';
import { getProductSource2 } from './APIs/APIProductSource2';
import { payDirect, getCashCoupon, getARBalance, getAllowARBalance, getCashCouponQuota } from './APIs/APIBalance';
import { getSplashFeelGreat } from './APIs/APISplash';

/** **Raven** 🐦 is a promise based HTTP client base on `AJAX` and `Mobx`. 
 * Officialy use to connect with **Ushop** APIs. */
class StoreRaven {

    constructor() {
        makeAutoObservable(this)
    }
    get get() { return $._get }
    get post() { return $._post }
    get hydraURL() { return $._getHydraEndpoint }

    /** Get translation JSON of specified languages. 
     * @param {string|array} languages could be **array** or **string** separated with comma (with case insensitive).
     * @example 
     * Raven.getTranslations('EN}TH') 
     * Raven.getTranslations(['EN'}'TH']) */
    get getTranslations() { return $._getTranslations }

    /** Request server for calculation of single order. Return its price} 
     * availability of items and usually handle any unattended errors of order data. */
    get getOrderCalc() { return $._getOrderCalc }

    get getValidateCart() { return $._getValidateCart }

    get getARBalance() { return $._getARBalance }
    get getAllowARBalance() { return $._getAllowARBalance }

    get switchHydraEndpoint() { return $._switchHydraEndpoint }
    // * Validation
    /** Request an **Address** data validation result. */
    get validateAddress() { return $._validateAddress }
    get validateAddressEnroll() { return $._validateAddressEnroll }
    /** Request an **Aramex** data validation result. */
    get validateAramex() { return $._validateAramex }
    // * Balance Features
    get getARBalance() { return getARBalance }
    get getAllowARBalance() { return getAllowARBalance }
    get getCashCoupon() { return getCashCoupon }
    // * Payment
    /** Request a direct payment process by **ARBalance** credit. 
     * @param {string} referenceID a specified reference id for request order. 
     * @param {object} dataPayment payment data for body.
     * @param {boolean} isNoTranslateError add qeuery string `not_translate_error=true`. */
    get payDirect() { return payDirect }
    /** Request a direct payment process by **ARBalance** credit. 
     * @param {string} referenceID a specified reference id for request order. */
    get payWithARBalance() { return $._payWithARBalance }
    // * Authorization
    get login() { return $._login }
    get getCustomer() { return $._getCustomer }
    // * Address History
    get getAddressHistory() { return $._getAddressHistory }
    get getAddressHistoryV2() { return $._getAddressHistoryV2 }
    get addAddressHistory() { return $._addAddressHistory }
    // * Zip Search
    get getZipSearch() { return $._getZipSearch }
    // * search address etc. parent
    get getAddSearchParent() { return $._getAddSearchParent }
    get getCitySearch() { return $._getCitySearch }
    get getCustomerExpand() { return $._getCustomerExpand }

    get getCartInfo() { return $._getCartInfo }
    get addCartInfo() { return $._addCartInfo }

    // * Order History Details
    get getOrderHistoryList() { return getOrderHistoryList }
    get getOrderHistoryCurrency() { return getOrderHistoryCurrency }
    get getOrderHistoryDetails() { return getOrderHistoryDetails }
    get getOrderHistoryByBAID() { return getOrderHistoryByBAID }

    // * Product Source
    get getProductSource() { return getProductSource }
    get getProductSource2() { return getProductSource2 }
    // home page 
    get getHomePageData() { return $._getHomePageData }
    get getBankBook() { return $._getBankBook }

    get getCashCoupon() { return getCashCoupon }

    get getSplitAddress() { return $._getSplitAddress }

    get getPhilippinesValidateEnroll() { return $._getPhilippinesValidateEnroll }
    get getPhilippinesValidateEnrollTax() { return $._getPhilippinesValidateEnrollTax }

    get getThailandValidateEnroll() { return $._getThailandValidateEnroll }

    get getJapanValidateEnroll() { return $._getJapanValidateEnroll }

    get getIndonesiaValidateEnroll() { return $._getIndonesiaValidateEnroll }

    get getTaiwanValidateEnroll() { return $._getTaiwanValidateEnroll }

    get getMalaysiaValidateEnroll() { return $._getMalaysiaValidateEnroll }

    get getCambodiaValidateEnroll() { return $._getCambodiaValidateEnroll }

    get getFullAddressTWN() { return $._getFullAddressTWN }

    get getSplashKit() { return $._getSplashKit }

    get getValidateSplash() { return $._getValidateSplash }

    get getMenuWithLogin() { return $._getMenuWithLogin }

    get getCashCouponQuota() { return getCashCouponQuota }

    get getSplashFeelGreat() { return getSplashFeelGreat }
}

const Raven = new StoreRaven()
export default Raven