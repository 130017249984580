import Raven from '../Raven';
import { getCustomerToken } from '../../components/utils/Customer';

/** Get order currency from API. 
 * @param {string} periodStart market as a country code (e.g. SG, AU, NZ). 
 * @param {string} periodEnd ship to country code (e.g. SG, AU, NZ). 
 * @param {string} href order invoice id.
 * @param {string} token order invoice id.
 */
export const getOrderHistoryList = (periodStart, periodEnd, href, token) => {

    const params = {
        expand: 'orderHistory',
        expandOrderHistory: 'order,rma',
        dateCreated: `[${periodStart}|${periodEnd}]`,
        customer: 'me|sponsoredCustomers?type=Customer',
        customerHref: href
    }

    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${token}`,
            'authorization-ushop': 'Bearer tVxbmKcjme'
        }
    }

    return (
        Raven.get(
            'adapter/dashboard', 
            params, 
            configs
        )
    )
}

/** Get order currency from API. 
 * @param {string} market market as a country code (e.g. SG, AU, NZ). 
 * @param {string} shipToCountry ship to country code (e.g. SG, AU, NZ). 
 * @param {string} orderId order invoice id.
 */
export const getOrderHistoryCurrency = (market, shipToCountry, orderId) => {
    const data = {
        market: market,
        shipToCountry: shipToCountry,
        orderId: orderId
    }

    const configs = {
        headers: { 
            'Content-Type': 'application/json', 
            'Accept': 'application/json' 
        }
    }

    return (
        Raven.post(
            'https://tkvjrct9we.execute-api.ap-southeast-1.amazonaws.com/production',
            data,
            configs
        )
    )
}

export const getOrderHistoryDetails = (token, orderUrl) => {
    return (
        Raven.get(
            orderUrl,
            null,
            {
                'headers': { 'Authorization': `Bearer ${token}` }
            }
        )
    )
}

export const getOrderHistoryByBAID = (baID) => {

    const params = {
        baId: baID,
        token: create(baID)
    }

    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${getCustomerToken()}`,
            'authorization-ushop': 'Bearer tVxbmKcjme'
        }
    }

    return (
        Raven.get(
            'adapter/genealogy',
            params,
            configs
        )
    )
}

const create = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = baId.toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}