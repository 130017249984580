import { devTools } from '../stores/MainStore';
import { storeAPI } from '../stores/StoreAPI';
import { isNothing, isSomething, isFunction, isString, isArray, isJSON, defaults } from '../components/utils/Utils';
import { apiURL as globalAPIURL, getStoreLanguageName, getCountryCode3 } from '../components/GlobalHelpers';
import { apiURL } from '../components/ShoppingHelper';
import $ from 'jquery';
import { getCustomerToken, getCustomerHref, getCustomerHrefValue, getCustomerID } from '../components/utils/Customer';
import { checkHost } from '../components/configs/ConfigsHeader';
import * as K from '../components/configs/Keywords';
import { CountryConfigs } from '../components/configs/Configs';
import trim from 'lodash/trim';
import extend from 'lodash/extend';
import lodashGet from 'lodash/get';
import StoreCountry from '@Stores/StoreCountry';
import { appConfig } from '@Components/config';
import { Country } from '@Stores/StoreConfigCountry';
import { lowerCase } from '@Components/utils/String';

const version = {
    subdomain: 'hydra',
    latest: 'v5a',
    append: '' //-test
}

export const createToken = (baID, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = defaults(baID, '').toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}

const APIEndpoint = 'https://member-calls2.unicity.com'
const APIEndpointDEV = 'https://member-calls2-dev.unicity.com'

const APIEndpointLegacy = {
    local: 'http://localhost:8888/api/unishop',
    dev: 'https://member-calls.unicity.com/api/unishop_dev',
    live: 'https://member-calls.unicity.com/api/unishop'
}

export const _getLegacyEndpoint = (forceLive = false) => {
    let resultEndpoint = APIEndpointLegacy.live
    if (forceLive) return resultEndpoint

    switch (checkHost()) {
        case K.Localhost:
            resultEndpoint = APIEndpointLegacy.dev
            break
        case K.Development:
            resultEndpoint = APIEndpointLegacy.live
            break
        default:
            resultEndpoint = APIEndpointLegacy.live
            break
    }

    return resultEndpoint
}

export const _switchHydraEndpoint = (endpoint) => {
    if (isNothing(endpoint)) return endpoint
    if (endpoint.indexOf('hydra') === -1) return endpoint

    //hydraqa

    switch (checkHost()) {
        case K.Localhost:
            version.append = ''
            break
        case K.Development:
            version.append = ''
            break
        default:
            version.append = ''
            break
    }

    const resultEndpoint = endpoint.replace('/v5a/', `/${version.latest}${version.append}/`)

    return resultEndpoint
}

export const _getHydraEndpoint = () => `https://${version.subdomain}.unicity.net/${version.latest}${version.append}`

/** An alias version for `_getHydraEndpoint()`. */
export const hydraURL = () => _getHydraEndpoint()

/**
 * @param {String} url - The end-point of request.
 * @param {Object} params - Any query string as an `Object`.
 * @param {Function} fulfilledInterceptor - If **Succeed** to request url, call this method before return the response.
 * @param {Function} rejectInterceptor - If **Fail** to request url, call this method before return the response.
 * @return {Promise}
 */
export const _get = (url, params, configs, fulfilledInterceptor, rejectInterceptor) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        if (lodashGet(configs, 'dev', false) === true) {
            resultEndpoint = `${APIEndpointDEV}/${url}`
        } else {
            resultEndpoint = `${APIEndpoint}/${url}`
        }
    }

    const options = {
        method: 'GET',
        url: resultEndpoint,
        ...configs
    }

    if (isSomething(params)) {
        options.data = params
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) fulfilledInterceptor(response)
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }

                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _post = (url, body, configs, fulfilledInterceptor, rejectInterceptor) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        resultEndpoint = `${APIEndpoint}/${url}`
    }

    const options = {
        method: 'POST',
        url: resultEndpoint,
        data: JSON.stringify(body),
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) fulfilledInterceptor(response)
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }
                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}
export const _delete = (url, configs, fulfilledInterceptor, rejectInterceptor) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        resultEndpoint = `${APIEndpoint}/${url}`
    }

    const options = {
        method: 'DELETE',
        url: resultEndpoint,
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }

                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _update = (url, body, configs, fulfilledInterceptor, rejectInterceptor) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        resultEndpoint = `${APIEndpoint}/${url}`
    }

    const options = {
        method: 'UPDATE',
        url: resultEndpoint,
        data: JSON.stringify(body),
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }
                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _getCustomerOnselfProfile = (baID) => {
    const tempToken = createToken(baID)
    const url = `https://member-calls2.unicity.com/adapter/etl/onself?baId=${baID}&token=${tempToken}`

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${getCustomerToken()}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    const result = _get(
        url, null,
        { 'headers': headers },
        (data) => {
            return data.profile
        }
    )

    return result
}
export const _getOrderCalc = (body) => {
    // reset hasError state every start
    storeAPI.orderCalc.hasError = false

    // hardcode additional keys
    body.order.notes = 'ushop|'

    // simulated errors by query string ?error
    if (devTools.isHasError) {
        body.order.lines.items = []
    }

    const result = _post(
        'ordercalc', body,
        { 'header': { 'Content-Type': 'application/json' } },
        () => { },
        () => { storeAPI.orderCalc.hasError = true }
    )

    return result
}

export const _getValidateCart = (body) => {
    // validate_cart/shopping/payment
    const result = _post(
        apiURL.shopping.validate_cart_api, body
    )

    return result
}

export const _getAllowARBalance = (unicityID) => {
    if (isNothing(unicityID)) return new Promise((resolve, reject) => reject('unicityID should not be empty.'))

    const endpoint = _getLegacyEndpoint(true)
    const result = _get(
        `${endpoint}/v1/global/verify/allow_ar_balance/${unicityID}`
    )

    return result
}

export const _getARBalance = () => {
    const hydraEndpoint = _switchHydraEndpoint(getCustomerHref())
    const result = _get(
        `${hydraEndpoint}/storecredit/${CountryConfigs.CountryCode()}`,
        null,
        {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCustomerToken()}`
            }
        }
    )

    return result
}

// * Validation
export const _validateAddress = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].validateAddress,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _validateAddressEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].validateAddressEnroll,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getPhilippinesValidateEnroll = (data) => {


    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidationV2,
        data,
        { 'header': { 'Content-Type': 'application/json' } },
    )

    return result
}

export const _getPhilippinesValidateEnrollTax = (data) => {

    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidationTax,
        data,
        { 'header': { 'Content-Type': 'application/json' } },
    )

    return result
}

export const _getThailandValidateEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidation,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getJapanValidateEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidation,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getIndonesiaValidateEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidation,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getTaiwanValidateEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].enrollValidationV3,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getMalaysiaValidateEnroll = (data) => {
    data.language = getStoreLanguageName()
    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].validateAddressEnroll,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getCambodiaValidateEnroll = (data) => {
    const result = _post(
        `https://member-calls2.unicity.com/format/KHM/enroll/v2/address`,
        data,
        { 'headers': { 'Content-Type': 'application/json' } }
    )
    return result
}




export const _validateAramex = (data) => {
    // data.language = getStoreLanguageName()
    const result = _post(
        `https://member-calls.unicity.com/SGP/SGP_Order_ARAMEX_ValidationV2.asp`,
        data,
        {
            dataType: 'json',
            beforeSend: (xhr, plianObject) => plianObject.data = `strData=${encodeURI(plianObject.data)}`
        }
    )

    return result
}
// --------------

// * Direct Payment
export const _payWithARBalance = referenceID => {
    const result = _post(
        `${_getLegacyEndpoint()}/v1/${CountryConfigs.CountryCode()}/payment/request/direct`,
        { reference_id: referenceID }
    )

    return result
}
// --------------

export const _login = (username, password) => {
    let data = {
        'type': 'base64',
        'value': btoa(unescape(encodeURIComponent(trim(`${username}:${trim(password)}`)))),
        'namespace': `${hydraURL()}/customers`
    }

    const result = _post(
        `${globalAPIURL.loginTokens}`, data,
        { 'headers': { 'Content-Type': 'application/json' } }
    )

    return result
}

export const _getCustomer = (token, href) => {
    const result = _get(
        `${href}`,
        { expand: 'customers, profilePicture' },
        { 'headers': { 'Authorization': `Bearer ${token}` } }
    )

    return result
}

/** Get translation JSON of specified languages. 
 * @param {string|array} languages could be **array** or **string** separated with comma (with case insensitive).
 * @example 
 * Raven.getTranslations('EN,TH') 
 * Raven.getTranslations(['EN','TH']) */
export const _getTranslations = languages => {
    let requestLanguages = ''
    if (isArray(languages)) {
        requestLanguages = languages.map((l) => {
            return `${l},`
        })
        requestLanguages = requestLanguages.slice(0, -1)
    } else {
        requestLanguages = languages
    }

    if (languages === undefined) {
        languages = appConfig.language[StoreCountry.CountryLowerCase()]
    }

    const result = _get(
        'dictionary/publish',
        { lang: `${languages}` }
    )

    return result
}

// Address History
export const _getAddressHistory = () => {

    const result = _get(
        'adapter/recentAddress',
        { customerHref: getCustomerHrefValue() },
        {
            headers: {
                'authorization-hydra': `Bearer ${getCustomerToken()}`
            }
        }
    )
    return result
}

export const _getAddressHistoryV2 = (baId) => {
    
    const result = _get(
        'etlV2/addressHistory',
        { baId: baId, ushopCountryCode: Country.getCode3() },
        {
            headers: {
                'authorization-hydra': `Bearer ${getCustomerToken()}`
            }
        }
    )
    return result
}

export const _getCartInfo = (userID) => {

    const result = _get(
        `${apiURL.cart.get}/${userID}`,
    )

    return result
}

export const _addAddressHistory = ({ shipToName, shipToAddress }) => {

    const result = _post(
        `adapter/recentAddress?customerHref=${getCustomerHrefValue()}`,
        {
            shipToName: { ...shipToName },
            shipToAddress: { ...shipToAddress }
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${getCustomerToken()}`
            }
        }
    )
    return result
}

export const _addCartInfo = (userID, cart) => {

    const data = {
        ba_id: userID,
        cart: cart
    }

    const result = _post(
        `${apiURL.cart.add}`,
        data
    )

    return result
}


export const _getZipSearch = (countryCode3, keyword, limit) => {
    const params = {
        country_code: countryCode3,
        keyword: keyword
    }

    if (limit) {
        extend(params, { limit: limit })
    }

    const result = _get(
        `unishop-fn-misc/city/search`,
        params
    )

    return result
}

export const _getAddSearchParent = (countryCode3, value, parent) => {
    const params = {
        parent,
        value
    }
    const result = _get(
        `unishop-fn-misc/city/country/${countryCode3}/list`,
        params
    )
    return result
}

export const _getCitySearch = (countryCode3) => {
    const result = _get(
        `unishop-fn-misc/city/country/${countryCode3}`
    )
    return result
}

export const _getCustomerExpand = (userID, expand) => {

    const params = {
        token: createToken(userID)
    }

    if (expand) {
        params.expand = expand
    }

    return (
        _get(
            `h/info/${userID}`,
            params
        )
    )
}

export const _getHomePageData = (countryCode3) => {
    let result = _get(
        `${APIEndpoint}/home/data/${countryCode3}`
    )
    return result
}

export const _getBankBook = () => {
    let result = _get(
        `${APIEndpoint}/unishop-fn-misc/key_value/${getCountryCode3().toLowerCase()}_banks`
    )
    return result
}

export const _getSplitAddress = (data) => {

    const result = _post(
        `${APIEndpoint}/fn-split-address`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return result
}

export const _getFullAddressTWN = (data) => {

    const result = _post(
        `${APIEndpoint}/etl/TWN/mapping_address`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    return result
}

export const _getSplashKit = (reference_id) => {

    const url = `https://member-calls2${/localhost/.test(window.location.href) || /ushop-dev1/.test(window.location.href) ? '-dev' : ''}.unicity.com/splash-page-link/main/${reference_id}`


    const result = _get(
        url
    )
    return result

}

export const _getValidateSplash = (data) => {

    const result = _post(
        globalAPIURL[CountryConfigs.CountryCode()].splashValidation,
        data,
        {
            beforeSend: (xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(plianObject.data)}`
            }
        }
    )

    return result
}

export const _getMenuWithLogin = (customerID, customerToken, country3Alpha, byPassCache = false) => {

    // console.log('customerID',customerID)
    // console.log('customerToken',customerToken)

    const params = {
        ushopCountryCode: lowerCase(country3Alpha || Country.getCode3()),
        baId: customerID
    }

    if (byPassCache) {
        params.byPassCache = 1
    }
    // customerToken = '0b1bece9-fa08-4636-acf7-8d68af2d3223' // mockup token
    return _get(
        'etlV2/menu',
        params,
        { 
            // dev: true,
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${customerToken}`
            }
        }
    )
}