import { makeAutoObservable } from 'mobx';
class StoreAutoShip {
    // item
    itemAutoship = []
    // info
    fullName = ''
    address1 = ''
    address2 = ''
    city = ''
    state = ''
    zip = ''
    email = ''
    mobilePhone = ''
    orderID = ''
    baDetails = null
    // payment
    paymentType = ''
    cardName = ''
    cardNumber = ''
    cardMonth = ''
    cardYear = ''
    cardCVV = ''
    proceedCheckout = false
    showPV = false
    // period
    period = ''
    periodListTrue = []
    periodList = []
    monthSelected = ''
    addressHref = ''
    startMonth = ''
    startMonthChanged = ''
    startLanguage = ''
    calculateOrderData = ''

    // page
    PAGE_INDEX = 1

    orderError = ''
    loadingSummary = false
    checkoutAddress = []
    rememberCard = false
    action = 'add'
    actionHref = ''
    alias = ''
    shipToAddress = {}

    errorCreateAutoShip = ''

    // format
    autoshipFormat = []
    autoshipTotalFormat = []
    isOpenSelectProductModal = false

    openSelectProductModal() {
        this.isOpenSelectProductModal = true
    }

    resetSelectProductModal() {
        this.isOpenSelectProductModal = false
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeAutoShip = new StoreAutoShip()