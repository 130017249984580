import { SESSION_TIMEOUT, SESSION_TIMESTAMP_KEY } from "@Components/config"
import { getQueryString } from "@Components/configs/ConfigsHeader"
import { isSomething } from "@Components/utils/Utils"

export class StoreSessionTimeout {
    static isSetSessionTimeStamp = false
    static sessionTimestamp = null
    static isSessionTimeout() {
        return isSetLocalStorage() && (new Date().getTime()) - getSessionTimestamp() >= SESSION_TIMEOUT
    }
    static initTimestamp() {
        if (!isSetLocalStorage() || hasSessionTimeout()) {
            StoreSessionTimeout.setTimestamp()
        }
    }
    static setTimestamp() {
        if (!isSetLocalStorage()) {
            StoreSessionTimeout.isSetSessionTimeStamp = false
        }
        if (!StoreSessionTimeout.isSetSessionTimeStamp) {
            if (hasSessionTimeout()) {
                setCustomSessionTimestamp()
            } else {
                if (StoreSessionTimeout.sessionTimestamp === null) {
                    setDefaultTimestamp()
                } else {
                    setSessionTimestamp(StoreSessionTimeout.sessionTimestamp)
                }
            }
            if (isSomething(getSessionTimestamp())) {
                StoreSessionTimeout.sessionTimestamp = getSessionTimestamp()
            }
        }
    }
    static clearTimestampAndRefresh() {
        clearSessionTimestamp()
        StoreSessionTimeout.sessionTimestamp = null
        StoreSessionTimeout.isSetSessionTimeStamp = false
        sessionStorage.clear()
        console.info(`🔄 --==--==-- Session Timeout --==--==--`)
        window.location.reload()
    }
}
function isSetLocalStorage() {
    return isSomething(getSessionTimestamp())
}
function hasSessionTimeout() {
    return isSomething(getQueryString().sessionTimeout) && !isNaN(parseFloat(getQueryString().sessionTimeout))
}
function setSessionTimestamp(value) {
    localStorage.setItem(SESSION_TIMESTAMP_KEY, value)
}
function clearSessionTimestamp() {
    localStorage.removeItem(SESSION_TIMESTAMP_KEY);
}
function getSessionTimestamp() {
    return localStorage.getItem(SESSION_TIMESTAMP_KEY)
}
function setDefaultTimestamp() {
    setSessionTimestamp(new Date().getTime())
}
function setCustomSessionTimestamp() {
    const minLeft = parseFloat(getQueryString().sessionTimeout)
    const customTimestamp = new Date().getTime() - (SESSION_TIMEOUT - minLeft * 1000 * 60)
    setSessionTimestamp(customTimestamp)
    console.info(`⏱ SET SESSION TIMESTAMP = ${(new Date(customTimestamp)).toLocaleString()}`);
}