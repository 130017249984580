import { makeAutoObservable } from 'mobx';

class StoreCart{
    current = []
    country = {}
    constructor() {
        makeAutoObservable(this)
    }
}

export const storeCart = new StoreCart()