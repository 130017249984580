import { getParameterByName } from '@Components/configs/ConfigsHeader';
import { isLanguageEnglish, randomNumber, randomString, isCountryPhilippines, isCountryJapan, getCountryCode } from '@Components/GlobalHelpers';
import { lowerCase } from '@Utils/String';
import { makeAutoObservable } from 'mobx';
import { ShippingMethods } from '../components/configs/ShopProfile';

class StoreShareThisCart {
    baInfo = {}
    shipToAddress = {}
    addressBook = {
        show: false
    }
    downlineStep = 0
    editMode = false
    editData = null

    constructor() {
        makeAutoObservable(this)
    }
}

class Store {
    currency = ''
    currencyObject = {}
    minStockQty = 0
    match = {}
    country = ''
    country_code = 'SG'
    indexTab = 0
    subIndexTab = 1
    subReportIndexTab = 1
    productData = []
    addressValidation = []
    newsDataSource = []
    mediaDataSource = []
    productDataSource = []
    loginHide = true
    seminarData = []
    imageLoading = true
    prevLang = null
    allowArBalance = false
    page = 'home'
    loggedIn = false
    flagCurrent = 'united-states.png'

    /** @deprecated Please use `language.listen()` instead. */
    language = {}
    /** @deprecated Please use `language.now()` instead. */
    languageCurrent = null
    /** @deprecated Please use `language.dictionaries` instead. */
    dictionaryAll = {}

    isLangSwitcherOpen = false
    authPass = false
    facebookConnected = false
    customerData = {}
    currentPage = 0
    shopProfileFromCart = false
    shopProfileIndexTab = 1
    quickenrollIndexTab = 1
    quickenrollDataSort = {}
    quickenrollStock = false
    siteSearch = ''
    shopProfileData = {}
    shopProfileProDataSource = {}
    shopProfileDownlineInfo = {}
    shopProfileDownlineAddr = {}
    shopProDataSort = {}
    shopProfileCart = []
    shopProfilelistCart = []
    shopProfileTotal = 0
    shopProfileTotalPv = 0
    shopProfileID = 0
    shopProfileAlert = false
    autoShipIndexTab = 1
    autoShipCart = []
    autoShipTotal = 0
    autoShipTotalPv = 0
    autoShipSelected = null
    showAlphabetNav = false
    showWebBackdrop = false
    navigator = {
        menuPush: 0,
        routePush: 0,
        hideSubmenuInSidebar: false,
        display: '',
        submenu: false,
        submenuList: [],
        showSubmenuCountryList: false
    }
    imageState = true
    catOpens = []
    marginTopMain = 0
    toast = {
        show: false,
        type: 'default',
        position: 'topRight',
        title: 'Test',
        message: 'Test test test test'
    }
    isMenuOpen = false
    legPeople = []
    homePage = 0
    mainMenu = []
    oneEightyDeliveryProvisionsPH = []
    //Enroll
    provinces = []
    banknames = []
    footer = {}
    messengerOpen = false
    isWebview = false
    classicHeader = false
    latestWebview = false
    deviceDetails = {}
    isIphone = false
    productRender = null
    filterProduct = false
    categories = null
    genealogy = {
        breadcrumbs: [],
        breadcrumbsName: [],
        breadcrumbsNativeName: [],
        filters: [],
        sort_by: 'id',
        show_all: true,
        firstLoaded: false,
        show_vip: true,
        prevDataSource: [],
        show_card: false,
        prevBreadcrumbs: null,
        breadcrumbsHistory: [],
        showBreadcrumbs: false,
        showBookmark: false,
        downlineList: [],
        view: 'table',
        searchBa: ''
    }
    homeLoaded = null
    bannerAdminData = null
    bannerDataAws = null
    shareCart = {
        productSource: [],
        enrollProductSource: [],
        checkoutCart: [],
        enrollCheckoutCart: [],
        validEnrollCheckoutCart: [],
        shopProfile: [],
        calculateOrder: {},
        calculateOrderData: '',
        referralDetails: {},
        checkoutAddress: {},
        minStockQty: 0,
        priceLevel: 1,
        percentDiscount: 0,
        proceedCheckout: false,
        // validateAllProductQTYMoreZero: false,
        sharedCardID: '',
        entryPeriord: '',
        referralId: '',
        referralName: '',
        referralNativeName: '',
        distributorDetails: {},
        distributorId: '',
        period: '',
        priceType: "retail_price",
        priceTypeStandard: "Customer",
        referralEmail: "",
        cartEnrolStep: 1,
        shipping: 2,
        cartEnrolData: {
            referralID: "",
            sponserID: "",
            enrolStep: "1"
        },
        enrollerDetails: {},
        loadingValidate: false,
        shoppingCreditCardDetails: {
            cardNumber: "",
            cardYear: "",
            cardCVV: "",
            cardMonth: "",
            cardName: "",
            validatePass: false
        },
        downlineInfo: {
            id: '',
            mobile: '',
            email: '',
            ship_method: ShippingMethods.delivery
        },
        prevDownlineAddress: {
            name: '',
            address1: '',
            address2: '',
            zip: '',
            state: '',
            city: 'none',
            country: '',
        },
        downlineAddress: {
            name: '',
            address1: '',
            address2: '',
            zip: '',
            state: '',
            city: 'none',
            country: '',
        },
        receive: {
            'email_confirmation': true,
            'invoice': true
        },
        no_ba_referral_id: '',
        no_ba_referral_id_name: '',
        email_confirmation: true,
        invoice: true,
        OrderDetailForm: false,
        termAndCondition: false,
        isOpenSelectProductModal: false,
        sharedStatus: ''
    }
    showCartModal = false
    showCart = false
    showCartOverlay = false
    cartHeight = window.innerHeight
    cartTab = ''
    cartTabScroll = false
    shareThisCartMode = false
    refreshShopprofile = false
    openCheckoutForm = false
    validateCheckStock = false
    stockError = false
    showLoader = false
    cartView = 'list'
    totalItemInCart = 0

    runningSnapShop = false

    topCategories = []

    // provinces
    listProvinces = []
    listProvincesEnglish = {}
    listProvincesNative = {}

    //Nationality
    listNationalitys = []

    selectedAddress = null
    editAddress = null

    countryData = null
    isCPWE = false
    profilePicture = null

    entryPeriod = ''

    constructor() {
        makeAutoObservable(this)
    }
}

class ShopStore {
    top10 = []
    gotStock = false;
    productDataSource = [];
    productCategories = []
    hotProduct = [];
    quickNavOpen = false
    quickNavOptions = []
    quickNavNext = null
    quickNavNextAfter = null
    siteSearch = ''
    passCheckOut = false
    validatedCartError = false
    subtotal = 0
    total = 0
    totalPv = 0
    weight = 0
    freight = 0
    enrollTotal = 0
    tempEnrollTotal = 0
    enrollTotalPv = 0
    enrollTotalLoading = false
    productFromHydraLoaded = false
    productFromServerLoaded = false
    tempAutoshipTotal = 0
    useCalculatedPriceFromHydra = false
    arbalance = 0
    arbalanceCurrency = null
    isEditARBalance = false
    productDataNew = []
    shoppingCart = []

    constructor() {
        makeAutoObservable(this)
    }
}

class EnrollStore {
    enrollFormat = []
    enrollBankFormat = []
    enrollTotalFormat = []
    total = 0
    totalPv = 0
    isCartFromShopping = false
    enrollProductSource = []
    enrollAutoshipSource = []
    enrollCart = []
    autoshipEnrollCart = []
    productEnrollCart = []
    enrollQuickNavOpen = false
    enrollQuickNavOptions = []
    enrollQuickNavNext = null
    enrollQuickNavNextAfter = null
    errorPoints = []
    enrollState = false
    siteSearch = ''
    currentPage = 1
    step = 1 // must start at 1
    stepBPass = false
    stepCPass = false
    stepBIR = 0
    isBAStatusActive = false
    quickenrollSignature = false
    agreeTCPass = false
    areas = []
    subAreas = []
    validationErrors = []
    sponsor;
    checkCalStore = []
    forceShowHideEnrollCart = false
    useAutoShipEnrollCart = false
    provinceList = []
    errorValidate = []
    subscribeType = 'PreferredCustomer'
    mainAddredShip = {
        city: "Singapore",
        country: "SG",
        state: "",
        zip: "099254",
        address1: "3 HarbourFront Place,",
        address2: "#01-01, HarbourFront Tower 2"
    }
    enrollData = {
        enroller: '',
        sponsor: '',
        enrollerName: '',
        displayEnrollerName: '',
        sponsorName: '',
        displaySponsorName: '',
        enrollerNativeName: '',
        sponsorNativeName: '',
        govId: '',
        govId2: '',
        govIdPassport: '',
        govIdWorkPermit: '',
        govIdBack: '',
        firstNameTh: '',
        fullAddress: '',
        lastNameTh: '',
        firstNameEn: '',
        lastNameEn: '',
        firstName: '',
        lastName: '',
        fullNameTh: '',
        fullNameEn: '',
        middleName: '',
        nationality: '',
        gender: 'none',
        maritalStatus: 'none',
        birthday: '',
        province: '',
        area: 'none',
        subArea: 'none',
        mainAddress: '',
        mainAddress2: '',
        country: 'none',
        countryCode: '',
        city: '',
        zip: '',
        zone: '',
        email: '',
        phone: '',
        mobile: '',
        password: '',
        password2: '',
        icFile: '',
        icFileBC: '',
        icFilePT: '',
        icFileBB: '',
        icSignature: '',
        icPassportFile: '',
        icWorkPermitFile: '',
        shippingmethod: '',
        bankName: '',
        bankNameCode: '',
        bankCode: '',
        bankNo: '',
        bankAccountName: '',
        bankAccountType: '',
        branchNumber: '',
        branchName: '',
        bankRoutingNumber: '',
        isFreeEnroll: '',
        enrollAutoShipCart: [],
        enrollNewId: '',
        enrollOrderId: '',
        enrollAutoShipStep: '',
        sameaddressAutoship: true,
        sameaddressFirstorder: true,
        disableInput: false,
        verifyGovId: false,
        ethnicity: '',
        shipping: '',
        warehouse: '',
        period: '',
        birProfileId: '',
        uploadBookBank: '',
        uploadCoapp: '',

        // JP
        firstNameJp: '',
        lastNameJp: '',
        fullNameJp: '',

        cardNumber: '',
        cardName: '',
        cardYear: '',
        cardMonth: '',
        cardCVV: '',

        payBy: '',
        // spouse
        spouseFirstName: '',
        spouseLastName: '',
        spouseFirstNameJp: '',
        spouseBirthday: '',
        spouseMainAddress: '',
        spouseMainAddress2: '',
        spouseZip: '',
        spouseMobilePhone: '',

        skip_birData: false,
        birData: {
            showWhatSwornDeclaration: "AnnexB1",
            incomeSourceType: "compensation",
            confirmedBusinessAddress: 'yes',
            isBIRRegisteredAddress: 'yes',
            isRegisteredInTaguig: 'yes',
            incomeFromUnicity: 'yes',
            nationality: "Filipino",
            deduction_type: "standard",
            grossIncome: "below_250k",
            valid_id_picture1: "",
            valid_id_picture2: "",
            declaration_file: "",
            withHoldingTax: "0",
            birReductionMonthly: false,
            birReductionOneTime: false,
            pdf_filename: "",
            birAuth: false,
            middleName: "",
            firstName: "",
            lastName: "",
            new_data: true,
            sales_tax: "8",
            vatType: "NON-VAT",
            tin_id: "",
            type: "",
            rdo: "044",
            skip: false,
            id: "",
            address: ""
        }

    }
    addressShipTo = {
        differentAddress: false,
        zip: '',
        country: '',
        address1: '',
        address2: '',
        firstNameJP: '',
        mobilePhone: ''
    }
    payment = {
        enroll: {
            paymenttype: '',
            cardNumber: '',
            cardName: '',
            cardMonth: '',
            cardYear: '',
            cardCVV: ''
        },
        autoship: {
            paymenttype: '',
            cardNumber: '',
            cardName: '',
            cardMonth: '',
            cardYear: '',
            cardCVV: ''
        }
    }
    autoshipData = {
        differentAddress: false,
        zip: '',
        country: '',
        address1: '',
        address2: '',
        firstNameJP: '',
        mobilePhone: ''
    }
    dev = false
    fileGovCardLocation = ''
    fileGovCardName = ''
    isTerminated = false

    isMenaCountry = false
    isWithoutProduct = false

    // Subscribe Confirmation
    isReceivedEmail = true
    isReceivedInvoice = true

    //
    stepName = ''

    enrollStep = 1
    verifiedGovID = false
    verifiedPassportID = false
    verifiedWorkPermitID = false
    verifiedEnroller = false
    verifiedSponsor = false
    errorValidate = []
    reloadData = false

    orderTerms = {}
    orderTermsJson = ''
    orderSummary = {
        terms: {
            subtotal: 0,
            total: 0,
            pv: 0,
            freight: {
                amount: 0
            }
        }
    }

    setMockupThailand() {
        this.enrollData.firstNameEn = 'Jaidee'
        this.enrollData.lastNameEn = 'Happylife'
        this.enrollData.firstNameTh = 'ใจดี'
        this.enrollData.lastNameTh = 'แฮปปี้ไลฟ์'
        this.enrollData.fullNameEn = this.enrollData.firstNameEn + ' ' + this.enrollData.lastNameEn
        this.enrollData.fullNameTh = this.enrollData.firstNameTh + ' ' + this.enrollData.lastNameTh
        this.enrollData.gender = 'female'
        this.enrollData.maritalStatus = 'Single'
        this.enrollData.birthday = '1999-10-05'
        this.enrollData.zip = '10120'
        this.enrollData.email = randomString(10, false, true, false) + '@' + randomString(4, false, true, false) + '.com'
        this.enrollData.mobile = '0924951530'
        this.enrollData.phone = '020501188'
        this.enrollData.password = '1234'
        this.enrollData.password2 = '1234'
        this.enrollData.enroller = '108357166'
        this.enrollData.sponsor = '108357166'
        this.enrollData.govId = getParameterByName('isTerminated') ? '3719329415669' : '5361973575688'
        this.enrollData.govIdBack = 'AA9900432122'
        this.enrollData.mainAddress = 'อาคารสุขสมฤดี ถนนสาทรใต้'
        this.enrollData.mainAddress2 = 'แขวงทุ่งมหาเมฆ เขตสาทร'
        this.enrollData.city = isLanguageEnglish() ? 'Bangkok' : 'กรุงเทพมหานคร'
        this.enrollData.dev = false
        this.enrollData.bankName = 'BBL'
        this.enrollData.bankNo = randomNumber(10)
        this.enrollData.bankAccountName = 'Jaidee Happylife'
        this.enrollData.bankNameCode = '002'
    }

    setMockupJapan() {
        this.enrollData.firstNameEn = 'Jaidee'
        this.enrollData.lastNameEn = 'Happylife'
        this.enrollData.firstNameJp = '武莉子'
        this.enrollData.lastNameJp = ''
        this.enrollData.fullNameEn = this.enrollData.firstNameEn + ' ' + this.enrollData.lastNameEn
        this.enrollData.fullNameJp = this.enrollData.firstNameJp + ' ' + this.enrollData.lastNameJp
        this.enrollData.gender = 'female'
        this.enrollData.maritalStatus = 'Single'
        this.enrollData.birthday = '1927-11-05'
        this.enrollData.zip = `${randomNumber(7)}`
        this.enrollData.email = `${randomString(10, false, true, false)}@unicity.com`
        this.enrollData.mobile = `${randomNumber(11, true)}`
        this.enrollData.phone = `${randomNumber(10, true)}`
        this.enrollData.password = '1234'
        this.enrollData.password2 = '1234'
        this.enrollData.enroller = '108357166'
        this.enrollData.sponsor = '108357166'
        this.enrollData.govId = `${randomNumber(9, true)}`
        this.enrollData.mainAddress = 'xxx'
        this.enrollData.mainAddress2 = '沖縄県八重山郡与那国町与那国'
        this.enrollData.city = ''
        this.enrollData.dev = false

        this.enrollData.bankNo = `${randomNumber(7, true)}`
        this.enrollData.bankName = 'ｵｵｶﾞｷｷｮｳﾘﾂ'
        this.enrollData.branchName = 'ﾓﾄﾔﾏ'
        this.enrollData.bankNameCode = `${randomNumber(4, true)}`
        this.enrollData.branchNumber = `${randomNumber(3, true)}`
        this.enrollData.bankAccountName = 'ﾓﾄﾔﾏ'
        this.enrollData.bankRoutingNumber = `${randomNumber(4, true)}`
        this.enrollData.bankAccountType = 'SavingsPersonal'

        this.enrollData.spouseFirstName = 'Takeru' // 武
        this.enrollData.spouseFirstNameJP = '武莉子'
        this.enrollData.spouseLastName = 'Riko'
        this.enrollData.spouseBirthday = '1982-02-28'
        this.enrollData.spouseMainAddress = '3-48-1'
        this.enrollData.spouseMainAddress2 = '神奈川県 横浜市南区 通町'
        this.enrollData.spouseZip = `${randomNumber(7)}`
        this.enrollData.spouseMobilePhone = '1234567'

    }
    setMockupTaiwan() {
        this.enrollData.firstNameEn = 'Geralt'
        this.enrollData.lastNameEn = 'OfRivia'
        this.enrollData['firstName@zh'] = '貨等服務'
        this.enrollData['fullName@zh'] = '貨等服務'
        this.enrollData.applicationNumber = '123456780'
        this.enrollData.gender = 'female'
        this.enrollData.birthday = '1992-11-16'
        this.enrollData.mainAddress = '3 Temasek Boulevard'
        this.enrollData.address2 = ''
        this.enrollData.city = '臺北市'
        this.enrollData.state = ''
        this.enrollData.zip = '212'
        this.enrollData.email = 'guiying2019@unicity.com'
        this.enrollData.mobile = '1234567890'
        this.enrollData.phone = '1234567890'
        this.enrollData.password = '1234'
        this.enrollData.password2 = '1234'
        this.enrollData.enroller = '108357166'
        this.enrollData.sponsor = '108357166'
        this.enrollData.govId = 'A123456789'
        this.enrollData.zone = ''
        enrollStore.shippingmethod = 'delivery'
    }

    setMockupIndonesia() {
        this.enrollData.firstNameEn = 'Yujin'
        this.enrollData.lastNameEn = 'An'
        this.enrollData.fullNameEn = this.enrollData.firstNameEn + ' ' + this.enrollData.lastNameEn
        this.enrollData.gender = 'female'
        this.enrollData.maritalStatus = 'Single'
        this.enrollData.birthday = '2003-08-01'
        this.enrollData.email = randomString(10, false, true, false) + '@' + randomString(4, false, true, false) + '.com'
        this.enrollData.mobile = '0915558899'
        this.enrollData.phone = '020501188'
        this.enrollData.password = '1234'
        this.enrollData.password2 = '1234'
        this.enrollData.enroller = '108357166'
        this.enrollData.sponsor = '108357166'
        this.enrollData.govId = randomNumber(16, true)
        this.enrollData.address1 = '3 Temasek Boulevard'
        this.enrollData.city = 'City'
        this.enrollData.street = 'City'
        this.enrollData.district = 'district'
        this.enrollData.province = 'province'
        this.enrollData.village = 'Village'
        this.enrollData.zip = '10120'
        // this.enrollData.bankName = 'BANK ANGLOMAS'
        // this.enrollData.bankNo = randomNumber(10)
        this.enrollData.bankAccountName = 'Jaidee Happylife'
        this.enrollData.shippingmethod = 'delivery'
    }

    setMockupMalaysia() {
        this.enrollData.firstNameEn = 'Guiying'
        this.enrollData.lastNameEn = 'Yee'
        this.enrollData.applicationNumber = '123456780'
        this.enrollData.gender = 'male'
        this.enrollData.maritalStatus = 'single'
        this.enrollData.birthday = '30 06 1999'
        this.enrollData.address1 = '3 Temasek Boulevard'
        this.enrollData.address2 = ',#01-351, Suntec City Mall (West Wing)'
        this.enrollData.city = 'Singapore'
        this.enrollData.state = ''
        this.enrollData.zip = '98109'
        this.enrollData.email = 'guiying2019@unicity.com'
        this.enrollData.mobile = '0987654321'
        this.enrollData.phone = '0987654321'
        this.enrollData.password = '1234'
        this.enrollData.password2 = '1234'
        this.enrollData.accountNumber = '1234567'
        this.enrollData.accountHolder = 'Abcde'
        this.enrollData.type = 'SavingsPersonal'
        this.enrollData.bankName = 'ABC'
        this.enrollData.branchName = 'West Beach'
        this.enrollData.bankCode = '1234'
        this.enrollData.branchNumber = '123'
        this.enrollData.routingNumber = '1234'
        this.enrollData.enroller = '108357166'
        this.enrollData.sponsor = '108357166'
        this.enrollData.govId = '333444333444'
        enrollStore.shippingmethod = 'delivery'
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class CheckOutStore {
    shoppingNotLogin = false
    referralParam = ''
    currentStep = 0
    fullName = ''
    firstNameEn = ''
    lastNameEn = ''
    address1 = ''
    address2 = ''
    country = ''
    province = ''
    city = ''
    zip = ''
    email = ''
    mobilePhone = ''
    homePhone = ''
    // Subscribe Confirmation
    isReceivedEmail = true
    isReceivedInvoice = true

    sns1 = ''
    sns2 = ''
    comment = ''
    checkout = false

    commissionMonth = ''
    commissionMonths = []

    items = []
    total = 0
    pv = 0
    cartMessage = ''
    orderTerms = {}
    orderTermsJson = ''
    orderSummary = {
        terms: {
            subtotal: 0,
            total: 0,
            pv: 0,
            freight: {
                amount: 0
            }
        }
    }
    shipping = '2'
    shippings = {
        box_1: '2',
        box_2: '2'
    }
    shipToTime = ''
    period = ''
    govId = ''
    birthDate = ''
    gender = 'none'
    dToken = null
    sendUser = 1 /*value = 1, for send user accout with email confirmation */
    baID = ''
    referralText = ''
    referralPassword = ''
    referralCheckedPassword = false
    referralBy = 'by_referral_id'
    referralValue = ''
    referralEnrollValid = false
    referralAddress = {
        delivery: {},
        pickUp: {},
        aramex: {}
    }
    referralAddressPH = {
        delivery: {},
        sameDay: {},
        halfday: {},
        pickUpOrtigas: {},
        pickUpTaguig: {}
    }
    billingAddress = {
        delivery: {},
        deliveryMainAddress: {},
        aramex: {},
        pickUp: {},
        pickUpOrtigas: {},
        pickUpTaguig: {},
        pickUpHoChiMinh: {},
        motorDelivery: {},
        pickUpSBY: {},
        deliverySBY: {},
        motorDeliverySBY: {}
    }
    billingAddressJP = {
        delivery: {},
        deliveryMainAddress: {}
    }
    billingAddressPH = {

        delivery: {},
        sameDay: {},
        deliveryMainAddress: {},
        halfday: {},
        pickUp: {},
        pickUpOrtigas: {},
        pickUpTaguig: {}
    }
    addressBook = {
        firstLoaded: false,
        isModified: false,
        addressList: {},
        selectedAddressKey: {},
        href: '',
        showList: false,
        showFormEdit: true,
        showFormNew: false,
        tempNewAddress: {},
        showconfirmation: false
    }
    loadingValidate = false
    birPhValidate = false
    referenceDetail = {}
    paymentType = ''
    reOrder = false
    doneCalculation = false
    isBackFromCart = false
    warehouse = ''

    calculated = []

    constructor() {
        makeAutoObservable(this)
    }
}


class CreditCardStore {
    cardName = ''
    cardNumber = ''
    cardMonth = ''
    cardYear = ''
    cardCVV = ''
    validatePass = false

    constructor() {
        makeAutoObservable(this)
    }
}

class StaticFooter {
    footerContact = null
    footerHours = null
    footerSocial = null
    footerQuickLink = null
    footerHelp = null
    footerGeneral = null
    footerFeedBack = null
    footerDisclaimer = null
    footerBanner = null
    footerPromotion = null
    footerVideo = null
    footerOrder = null
    footerTextTitle = null
    footerMessenger = null
    footerDecimalController = null
    footerMainPic = null
    footerEnrollWithAutoShip = null
    footerMisc = {
        ushopEnrollBirthday: null
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class seminar {
    rank = ''
    remark = ''

    constructor() {
        makeAutoObservable(this)
    }
}

class BirData {
    newEnrollmentData = {
        tax_id: "",
        enroller_id: "",
        enroller_name: "",
        enroller_name_native: "",
        sponsor_id: "",
        sponsor_name: "",
        sponsor_name_native:  "",
        firstname_en: "",    
        middlename_en: "",
        lastname_en: "",
        gender: "male",
        marital_status: "single",
        birthdate: "",
        address1: "",
        address2: "",
        province: "",
        zip: "",
        phone: "",
        email: "",
        password1: "",
        password2: "",
        shipment_options: "",
        period: "",
        items: "",
        
        personal_is_bir_registered_address: "yes",
        personal_is_registered_in_Taguig: "yes",
        personal_rdox: "044",
        personal_confirmed_business_address: "yes",
        personal_photo_tax_id1: "www.google.com",
        personal_photo_tax_id2: "www.google.com",
    
        tax_unicity_only_source_income: "yes",
        tax_gross_income: "below_250k",
        tax_vat_type:	"NON-VAT/VAT",
        tax_sales_tax: "8",
        tax_deduction_type: "standard",
    
        bir_auth: true,
        bir_reduction_monthly: true,
        bir_reduction_one_time: true

    }
    profile = {

        
        showWhatSwornDeclaration: "AnnexB1",
        incomeSourceType: "compensation",
        confirmedBusinessAddress: true,
        isBIRRegisteredAddress: 'yes',
        isRegisteredInTaguig: 'yes',
        incomeFromUnicity: true,
        nationality: "Filipino",
        deduction_type: "standard",
        grossIncome: "below_250k",
        valid_id_picture: [],
        declaration_file: "",
        withHoldingTax: 0,
        birReduction: [],
        pdf_filename: "",
        birAuth: false,
        middleName: "",
        firstName: "",
        lastName: "",
        new_data: true,
        sales_tax: "8",
        vatType: "NON-VAT",
        tin_id: "",
        type: "",
        rdo: "044",
        skip: false,
        id: "",
        address: ""
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class StoreTab {
    tab_open = ''

    constructor() {
        makeAutoObservable(this)
    }
}

class SaveToAutoship {
    autoShipIndexTab = 0
    autoshipCart = null

    constructor() {
        makeAutoObservable(this)
    }
}

class ShareThisCartStore {
    priceLevel

    constructor() {
        makeAutoObservable(this)
    }
}

/** Observable parameters for rendering, loading API or other basic need. */
class StoreManager {
    // spinner handler
    isReadyToDisplayHome = false
    // local storage shortcut pass
    isLocalStorageAPI = false
    // LoadDBProducts handler
    isCartRestoredDone = false
    // footers handler
    isFooterDone = false
    isFooterStaticDone = false
    // spinner
    isSpinner = false

    constructor() {
        makeAutoObservable(this)
    }
}

class ClockManager {
    now = null

    constructor() {
        makeAutoObservable(this)
    }
}

/** Observable parameters for development tools. */
class DevelopmentTools {
    isSimulateLive = false
    isSimulateMaintenance = false
    isShowDevLog = false
    isShowInfoLog = false
    isShowProfilerLog = false
    isShowDictionaryLabel = false
    isShowOldCart = false
    isAddressBookEmpty = false
    isAddressBookMockup = false
    isHasError = false
    simulateDecimal = null
    customizerHeaderType = 'sticky-logo'
    customizerProductsCat = 'default'
    useCustomizer = false
    hideDevMessage = true
    addressBarHeight = 0
    viewPortHeight = 0
    isGesture = false
    loadingLog = ''

    constructor() {
        makeAutoObservable(this)
    }
}

class ProfileStore {
    isOrderHistoryLoaded = true
    openAddressEditor = false
    ordersDataSource = []

    constructor() {
        makeAutoObservable(this)
    }
}

export const store = new Store()
export const shopStore = new ShopStore()
export const defaultShopStore = new ShopStore()
export const enrollStore = new EnrollStore()
export const defaultEnrollStore = new EnrollStore()
export const checkOutStore = new CheckOutStore()
export const defaultCheckOutStore = new CheckOutStore()
export const checkOutEnrollStore = new CheckOutStore()
export const checkOutPCEnrollStore = new CheckOutStore()
export const defaultCheckOutEnrollStore = new CheckOutStore()
export const shoppingCreditCardStore = new CreditCardStore()
export const enrollCreditCardStore = new CreditCardStore()
export const staticFooter = new StaticFooter()
export const seminarData = new seminar()
export const birData = new BirData()
export const saveToAutoship = new SaveToAutoship()
export const shareThisCartStore = new ShareThisCartStore()
export const storeManager = new StoreManager()
export const clockManager = new ClockManager()
export const devTools = new DevelopmentTools()
export const profileStore = new ProfileStore()
export const store_tab = new StoreTab()
export const shareThisCart = new StoreShareThisCart()