import { makeAutoObservable } from 'mobx';
import { PLATFORMS_MAP, PLATFORMS_MODEL_MAP, OS_MAP, BROWSER_MAP } from '@Bowser';

class StoreBowser {
    model = ''
    type = ''
    os = ''
    browser = ''

    constructor() {
        makeAutoObservable(this)
    }

    isDesktop() {
        return this.type === PLATFORMS_MAP.desktop
    }
    isMobile() {
        return this.type === PLATFORMS_MAP.mobile
    }
    isTablet() {
        return this.type === PLATFORMS_MAP.tablet
    }
    isTV() {
        return this.type === PLATFORMS_MAP.tv
    }
    isIPad() {
        return this.model === PLATFORMS_MODEL_MAP.iPad
    }
    isOSAndroid() {
        return this.os === OS_MAP.Android
    }
    isOSiOS() {
        return this.os === OS_MAP.iOS
    }
    isSafari() {
        return this.browser === BROWSER_MAP.safari
    }
    isEdge() {
        return this.browser === BROWSER_MAP.edge
    }
}

export const storeBowser = new StoreBowser()