import { capitalize, get, lowerCase, upperCase } from 'lodash';
import { makeAutoObservable } from 'mobx';

class StoreCountry {
    countryList = []
    isLoading = true
    currentCountry = {}
    bgImg = {
        desktop: '',
        mobile: ''
    }
    maintenance = false
    country = {
        country: '',
        live: '',
        maintenance_mode: false,
        native_name: '',
        roman_name: '',
        short: '',
        shorter: '',
        test: ''
    }
    icon = []
    logo = {
        color: '',
        logo_url: ''
    }
    title = {
        text: '',
        color: ''
    }

    GACode = {
        singapore: 'UA-119346265-1',
        thailand: 'UA-119346265-2',
        japan: 'UA-119346265-4',
        india: 'UA-119346265-1',
        philippines: 'UA-119346265-3',
        united_states: 'UA-119346265-1',
        bahrain: 'UA-119346265-1',
        vietnam: 'UA-119346265-6',
        indonesia: 'UA-119346265-5',
        malaysia: '',
        hongkong: '',
        australia: 'UA-205115347-1',
        newzealand: '',
        taiwan: '',
    }

    GACode2021 = {
        singapore: 'UA-188539463-8',
        thailand: 'UA-188539463-2',
        japan: 'UA-188539463-9',
        india: '',
        philippines: 'UA-188539463-4',
        united_states: '',
        bahrain: '',
        vietnam: '',
        indonesia: 'UA-188539463-3',
        malaysia: 'UA-188539463-7',
        hongkong: 'UA-188539463-5',
        australia: '',
        newzealand: '',
        taiwan: 'UA-188539463-6',
    }

    FBPixelCode = {
        singapore: '388467111504630',
        thailand: '301116716951336',
        japan: '388467111504630',
        india: '388467111504630',
        philippines: '388467111504630',
        united_states: '388467111504630',
        bahrain: '388467111504630',
        vietnam: '388467111504630',
        indonesia: '388467111504630',
        malaysia: '',
        australia: '',
        hongkong: '',
        newzealand: '',
        taiwan: '',
    }

    GAAdwordsCode = {
        singapore: 'AW-869476933',
        thailand: 'AW-869476933',
        japan: 'AW-869476933',
        india: 'AW-869476933',
        philippines: 'AW-869476933',
        united_states: 'AW-869476933',
        bahrain: 'AW-869476933',
        vietnam: 'AW-869476933',
        indonesia: 'AW-869476933',
        malaysia: '',
        australia: '',
        newzealand: '',
        taiwan: ''
    }


    CountryLowerCase() {
        return lowerCase(get(this.country, 'country', '')).replace(/\s/g, '')
    }

    CountryCapitalize() {
        return get(this.country, 'roman_name', '')
    }

    CountryUpperCase() {
        return upperCase(get(this.country, 'country', '')).replace(/\s/g, '')
    }

    Country2() {
        return this.country.shorter
    }

    Country3() {
        return this.country.short
    }

    CountryNative() {
        return this.country.native_name
    }

    init(data) {
        this.countryList = data.data
        this.bgImg = data.bg_img
        this.logo = data.logo
        this.icon = data.icon
        this.title = data.title
    }

    constructor() {
        makeAutoObservable(this)
    }

    isJapan() {
        return upperCase(this.Country2()) === 'JP'
    }
}

export default new StoreCountry()