import { makeAutoObservable, observable } from 'mobx';

class StoreElement {
    backdrop = {
        enabled: false,
        backgroundColor: 'rgba(0,0,0, 0.35)',
        zIndex: 10
    }

    mobileMode = window.innerWidth < 768

    setMobileMode(value) {
        this.mobileMode = value
    }

    isMobile(forceMobileMode = false) {
        return forceMobileMode ? true : this.mobileMode 
    }

    constructor(props) {
        makeAutoObservable(this)
    }
}

export const storeElement = new StoreElement()
