import { makeAutoObservable } from 'mobx';

class StoreProfile {
    profileData = null
    successTrackerData = null
    commissionData = null
    facebookLoginData = null
    seminarData = null
    addressBookData = null
    orderHistoryData = null
    genealogyData = null
    lsbData = null
    editBaInfo = false
    saveBaInfo = false
    editForms = {
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        email: '',
        hasError: false
    }
    periodSelected = 0
    globalCardWidth = 'card-354'
    needReload = false
    genealogyAge = 0
    genealogyCache = false
    isStatusE = false
    isViewBa = false
    isCPWE = false
    profileDataReady = false
    genealogyDataReady = false
    dataReady = false
    rankSelected = ''

    boxProfileHeight = 0
    boxBaInfoHeight = 0
    boxSeminarHeight = 0
    boxGenealogyHeight = 0
    boxOrderHistoryHeight = 0
    boxSuccessTrackerHeight = 0
    boxCommissionHeight = 0
    boxLsbHeight = 0

    closeHeaderMenu = false

    enroller = null
    sponsor = null

    seminarHeaderTab = 0

    constructor() {
        makeAutoObservable(this)
    }

    resetData() {
        this.profileData = null
        this.successTrackerData = null
        this.commissionData = null
        this.facebookLoginData = null
        this.seminarData = null
        this.addressBookData = null
        this.orderHistoryData = null
        this.genealogyData = null
        this.enroller = null
        this.sponsor = null
        this.profileDataReady = false
        this.genealogyDataReady = false
        this.dataReady = false
        this.seminarHeaderTab = 0
    }
}

export const storeProfile = new StoreProfile()