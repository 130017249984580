import Raven from '../Raven'
import { _getLegacyEndpoint } from '../APIs'
import { getCustomerID } from '../../components/utils/Customer'
import { isNothing, isBoolean } from '../../components/utils/Utils'
import { Country } from '../../stores/StoreConfigCountry'
import { storeBalance } from '../../stores/StoreBalance'
import { logi, loge } from '../../components/utils/PikaLog'
import last from 'lodash/last'
import throttle from 'lodash/throttle'
import { hasQueryString } from '@Components/configs/ConfigsHeader'
import { createCustomerToken } from '@Customer'
import { removeSpecial } from '@Utils/String'

export const payDirect = (dataPayment, isNoTranslateError = true) => {
    const result = Raven.post(
        `${_getLegacyEndpoint(true)}/v1/${Country.getCode2()}/payment/request/direct${isNoTranslateError ? '?not_translate_error=true' : ''}`,
        dataPayment
    )

    return result
}

export const getCashCouponQuota = (customerID, pv, period, isDebug = false) => {
    const legitPeriod = removeSpecial(period)
    const result = Raven.get(
        `unishop-fn-misc/cashcoupon_quota/v2/${customerID}?pv=${pv}&period=${legitPeriod}${isDebug ? '&debug=true' : ''}`
    )

    return result
}

export const getCashCoupon = (customerID) => {
    const result = Raven.get(
        `h/cashcoupon/${customerID}`,
        {
            token: createCustomerToken(customerID)
        }
    )

    return result
}

export const getAllowARBalance = (unicityID) => {
    if (isNothing(unicityID)) return new Promise((resolve, reject) => reject('unicityID should not be empty.'))

    const endpoint = _getLegacyEndpoint(true)
    const result = Raven.get(
        `${endpoint}/v1/global/verify/allow_ar_balance/${unicityID}`
    )

    return result
}

export const getARBalance = () => {
    return new Promise()
    /* const hydraEndpoint = Raven.switchHydraEndpoint(StoreAuth.Href())
    const result = Raven.get(
        `${hydraEndpoint}/storecredit/${Country.getCode2()}`,
        null,
        {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StoreAuth.GetToken()}`
            }
        }
    )

    return result */
}

const checkAdditionalLogic = (overwrite = false, currentType, currentAllow, currentBalance, customerID) => {
    let isAllow = null

    switch (currentType) {
        case 'ARBalance':
            // Ref: https://app.clickup.com/t/49ub26
            // https://u67488.p.clickup-attachments.com/u67488/fdb8b45f-f823-409d-87fe-1d49f794d07a/image.png?view=open
            isAllow = currentBalance > 0
            break
        case 'CashCoupon':
            // Ref: https://app.clickup.com/t/49ub26
            // https://app.clickup.com/t/49ub26?comment=56245711
            isAllow = currentBalance > 0
            break
    }

    if (isBoolean(isAllow)) {
        if (overwrite === true) {
            return isAllow
        } else {
            if (currentAllow) {
                return isAllow
            } else {
                return false
            }
        }
    } else {
        return null
    }
}

// * START -> Balance Feature: Cash Coupon & ARBalance
const processBalance = (currentType, currentAllow, currentBalance, customerID) => {
    let resultAllow = currentAllow
    // check any additional logic from front-end and overwrite.
    const finalizeAllow = checkAdditionalLogic(true, currentType, currentAllow, currentBalance, customerID)
    logi('💵 [BALANCE] Front-end finalize allow logic ->', isBoolean(finalizeAllow) ? finalizeAllow : 'None')
    if (isBoolean(finalizeAllow)) {
        resultAllow = finalizeAllow
    }
    storeBalance.type = currentType
    storeBalance.balance = currentBalance
    // storeBalance.currency = customerID
    storeBalance.isAllow = resultAllow
}

const requestBalance = () => {
    let [currentType, currentAllow, currentBalance, customerID, currentCurrency] = ['', false, 0, '', '']

    if (Country.isThailand()) {
        currentType = 'CashCoupon'
        storeBalance.isLoading = true
        getCashCoupon(customerID)
            .then(({ items }) => {
                if (hasQueryString('credit')) {
                    throw 'mockup error'
                }

                if (items.length > 0) {
                    const balance = parseFloat(last(items).runningBalance)
                    currentBalance = balance
                    currentAllow = true
                    logi(`💵 [BALANCE] CashCoupon found -> Balance: [${balance} ${''}]`)
                } else {
                    currentAllow = false
                    loge(`💵 [BALANCE] CachCoupon NOT found.`)
                }
                storeBalance.isLoading = false
                processBalance(currentType, currentAllow, currentBalance, customerID)
            })
            .catch(response => {
                currentAllow = false
                storeBalance.api.hasError = true
                storeBalance.api.type = 'warning'
                storeBalance.api.messages = ['u_all_balance_error_unknow']

                loge(`💵 [BALANCE] CashCoupon error. => ${response}`)
                storeBalance.isLoading = false
                processBalance(currentType, currentAllow, currentBalance, customerID)
            })
    } else if (Country.isSingapore()) {
        currentType = 'ARBalance'
        Raven.getAllowARBalance(getCustomerID())
            .then((response) => {
                logi('💵 [BALANCE] ARBalance is allow ->', response)
                Raven.getARBalance()
                    .then(responseARB => {
                        logi('💵 [BALANCE] ARBalance found ->', responseARB)
                        const { items: [balanceItems] } = responseARB
                        if (Object.keys(responseARB.items)[0] !== undefined) {
                            const currency = Object.keys(balanceItems)[0]
                            currentBalance = balanceItems[currency]
                            currentCurrency = currency
                        }
                        currentAllow = true
                        processBalance(currentType, currentAllow, currentBalance, currentCurrency)
                    })
                    .catch(() => {
                        loge(`💵 [BALANCE] ARBalance NOT found.`)
                        currentAllow = false
                        processBalance(currentType, currentAllow, currentBalance, currentCurrency)
                    })
                    /* .finally(() => {
                        processBalance(currentType, currentAllow, currentBalance, currentCurrency)
                    }) */
            })
            .catch(response => {
                loge('[BALANCE] ARBalance is NOT allow ->', response)
                currentType = ''
                currentAllow = false
                processBalance(currentType, currentAllow, currentBalance, currentCurrency)
            })
    } else {
        loge(`💵 [BALANCE] feature is NOT support on this country.`)
    }
}

/** Request balacne features update from API (Cash Coupon and ARBalance). 
 * 
 * Can invoke this method only once per 5 seconds to prevent any pointless request.
*/
export const updateBalance = throttle(requestBalance, 5000, { trailing: false })
// * END <- Balance Feature