import { makeAutoObservable } from 'mobx'
import { logi, logn } from '@Utils/PikaLog'

class StoreBalance {
    _isAllow = false
    _isForceDisabled = false
    isEditing = false
    _isExceedQuota = false
    balance = 0
    balancePlace = 0
    balanceQuota = 0
    currency = ''
    type = ''
    isLoading = true
    
    get isAllow() {
        logi('💵 [BALANCE] Allow Check | GeneralAllow:', this._isAllow, '| Exceed:', this.isExceedQuota, '| ForceDisabled:', this._isForceDisabled)
        logi('💵 [BALANCE] Allow Check | Allow Result:', (this._isAllow && (this.isExceedQuota === false) && this._isForceDisabled === false))
        return (this._isAllow && (this.isExceedQuota === false)) && this._isForceDisabled === false
    }

    set isAllow(bool) {
        this._isAllow = bool
    }

    get isExceedQuota() {
        return false // this._isExceedQuota // remove qouta - https://app.clickup.com/t/1wv4kz7
    }

    api = {
        type: '', // 'error', 'warning'
        messages: [],
        hasError: false,
        hasMockError: false,
        configs: {
            // admin config
            enable: false,
            divisibleNumber: 1,
            isHideZero: false,
            isEditable: false,
            // retry config
            isRetryOnAPIFail: true,
            maxRetryAttempt: 2,
            currentRetryAttempt: 0
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    startForceDisabled(reason = '') {
        logn('💵 🚫 [BALANCE] Forced Disabled | Reason:', reason)
        this._isForceDisabled = true
    }

    stopForceDisabled() {
        this._isForceDisabled = false
    }

    startExceedQuota() {
        this._isExceedQuota = true
    }

    stopExceedQuota() {
        this._isExceedQuota = false
    }
}

export const storeBalance = new StoreBalance()