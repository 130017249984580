
import { makeAutoObservable } from 'mobx'
import { Country } from './StoreConfigCountry'
import { inArray } from '@Components/config'
import { getEnroll } from '@Services/Network'
import { last } from 'lodash'

class StoreEnroll {
    cart = []
    productSource = []
    productSourcePH = []
    productRenewSource = []
    selectedItems = []
    selectedItemsPH = []
    selectedItemPHForCheck = []
    checkoutCart = []
    DeleteID = ''
    starterKitItems = []
    starterKitItemsPH = []
    renewID = ''
    starterKitSelected = ''
    proceedCheckout = false
    proceedOrderSummary = false
    openDeleteModel = false
    orderError = false
    errorMessage = []
    checkoutAddress = {}
    totalPv = 0
    priceLevel = 2
    priceLevelString = 'wholesale'
    hasDiscontinuedProduct = false
    showAlert = false
    calculateOrderData = ''
    calculateOrder = ''
    errors = ''
    baDetails = {}
    baId = ''

    isFirstTimeProfileID = true
    isLoadingProfileID = false
    isOpenSelectProductModal = false
    
    constructor() {
        makeAutoObservable(this)
    }

    openSelectProductModal() {
        this.isOpenSelectProductModal = true
    }

    resetSelectProductModal() {
        this.isOpenSelectProductModal = false
    }

    get StarterKitItems() {
        const starterKits = []
        storeEnroll.productSource.map(item => {
            if (inArray(item.item_code, storeEnroll.starterKitItems)) {
                starterKits.push(item)
            }
        })
        return starterKits
    }

    StarterKitItemCodeOnlyPH() {
        let staterKitCodeOnly = storeEnroll.starterKitItemsPH.map(e => e.item_code)
        return staterKitCodeOnly
    }

    InitStarterKit(starterKits, refId) {
        return new Promise((resolve, reject) => {
            let newStarterKit = starterKits
            this.productSource = newStarterKit
            this.productRenewSource = newStarterKit
            //setLoading(false)
            let tempStarterKitItems = []
            let tempStarterKitItems2 = []

            starterKits.map((v, k) => {

                tempStarterKitItems.push(v.item_code)

                this.productSource[k].qty = 1

            })

            tempStarterKitItems.map((v, k) => {
                tempStarterKitItems2.push(v)
            })

            this.starterKitItems = tempStarterKitItems2

            if (this.starterKitItems.length === 1) {
                this.starterKitSelected = last(tempStarterKitItems2)
            }

            if (tempStarterKitItems2) {

                // setLoading(false)
            }
            if (Country.isPhilippines() !== true) {
                if (refId) {
                    getEnroll((response) => {

                        if (response.statusText !== 'error') {
                            this.productSource = JSON.parse(response.data.step_select_products)
                            // setLoading(false)
                        }
                    }, refId)

                }

            }

            resolve(true)
        })
    }

    getItemFromProductSource(id) {
        return this.productSource.get(id)
    }
}

export const storeEnroll = new StoreEnroll()