import Raven from '../Raven';
import { CountryConfigs } from '../../components/configs/Configs';
import $ from 'jquery';
import { isNothing, isSomething, isFunction, isString, isArray, isJSON } from '../../components/utils/Utils';


export const getProductSource = (isHydra, priceLevel, warehouse) => {

    const url = 'https://member-calls.unicity.com/api/unishop/v1/global/getproducts'
    const params = {
        country_code: CountryConfigs.CountryCode3()
    }

    if (warehouse) {
        params.warehouse = warehouse
    }

    if (isHydra === false) {
        params.secret = 'yes'
        params.price_level = priceLevel
    }

    return (
        Raven.get(
            url,
            params
        )
    )
}