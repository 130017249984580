import { reaction } from 'mobx';
import { devTools, store, storeManager, shopStore, staticFooter } from './MainStore';
import { language } from './StoreLanguage';
import { logw } from '../components/utils/PikaLog';
import { storeShopProfile } from './StoreShopProfile';
import { updateTranslation } from '../components/utils/Translator';
import Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

// ** Watch app when it is ready to display home. **
reaction(() => storeManager.isReadyToDisplayHome,
    isReady => {
        if (isReady === true) {
            logw('App is ready to display.')
        }
    }
)

// ** Watch app current language and set something if it changed. **
reaction(
    () => language.current,
    lang => {
        // support old api, until we updated all code
        store.languageCurrent = lang

        // update footer language
        language.memoFooter()

        if (storeManager.isFooterStaticDone) {
            // logw('Switched language to:', language)
            updateTranslation(lang)
        }
        if (store.prevLang === null || store.prevLang !== language.current) {
            store.imageLoading = true
        } else {
            if (store.prevLang !== language.current) {
                store.imageLoading = false
            }
        }
        store.prevLang = language.current
    }
)

reaction(() => shopStore.quickNavNext,
    isChanged => {
        let elem = document.getElementById('ref-' + shopStore.quickNavNext);
        if (elem) {
            const scrollTo = /a-to-z/.test(window.location.pathname) ? 50 : 140
            let amount = elem.offsetTop + scrollTo - 40
            scroll.scrollTo(amount);
            shopStore.quickNavNextAfter = shopStore.quickNavNext
        }
    }
)

reaction(() => store.shareThisCartMode,
    isChanged => {
        if (isChanged === false) {
            store.shopProDataSort = {}
            store.shopProfileCart = []
            store.shopProfilelistCart = []
            store.shopProfileTotal = 0
            store.shopProfileTotalPv = 0
            store.shopProfileID = 0
            storeShopProfile.hasDiscontinuedProduct = false
            
            if (/shopprofile\/edit/.test(window.location.pathname)) {
                shopStore.productDataSource.map((product, k) => {
                    if (product) {
                        product.qty = 0
                        product.proQty = 0
                    }

                    let shopProfileItem = store.shopProfileCart.find(item => item.item_code === product.item_code)
                    if (shopProfileItem) {
                        const buyQty = parseInt(shopProfileItem.qty)
                        product.qty = buyQty
                        product.proQty = buyQty
                    }

                    return product
                })
                
            }

        } else {
            shopStore.productDataSource.map((product, k) => {
                if (product) {
                    product.qty = 0
                    product.proQty = 0
                }

                let shopProfileItem = store.shopProfileCart.find(item => item.item_code === product.item_code)
                if (shopProfileItem) {
                    const buyQty = parseInt(shopProfileItem.qty)
                    product.qty = buyQty
                    product.proQty = buyQty
                }

                return product
            })
            shopStore.total = 0
            shopStore.totalPv = 0
        }
    }
)

reaction(() => devTools.customizerHeaderType,
    isChanged => {
        if (isChanged !== 'sticky-when-scroll') {
            let headerBar = document.getElementById('headerBar')
            headerBar.classList.remove('nav-down')
        }
        window.scrollTo(0, 1);
        window.scrollTo(0, 0);
    }
)

reaction(
    () => staticFooter.footerGeneral,
    (general, _, reaction) => {
        if (general) {
            const currencyCode = general.currency_code
            store.currencyObject = currencyCode
            if (store.currency === '') {
                store.currency = language.listenObject(currencyCode)
            }
            reaction.dispose()
        }
    }, { fireImmediately: true }
)