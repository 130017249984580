import StoreCountry from '@Stores/StoreCountry'
import { lowerCase } from 'lodash'
import ReactGA from 'react-ga'
import { makeAutoObservable } from 'mobx'
class TrackingEvents {
    get trackingPages() {
        return [
            'australia/splash',
            'australia/splash/monthly-subscription',
            'australia/splash/monthly-subscription/payment',
            'australia/splash/monthly-subscription/shipping-detail',
            'australia/splash/monthly-subscription/payment-response/success',
            'australia/splash/one-time-purchase',
            'australia/splash/one-time-purchase/payment',
            'australia/splash/one-time-purchase/shipping-detail',
            'australia/splash/one-time-purchase/payment-response/success'
        ]
    }

    CommitToGoogleAnalytic(label, category, action, fullCountryName) {
        console.log('Tracking event label:', label)

        let GACode = 'UA-205115347-1'
        let GACodeAll = 'UA-205115347-2'

        const eachMarket = lowerCase(fullCountryName)
        const allMarketName = 'UshopLink'
        const trackers = [
            {
                trackingId: GACodeAll,
                gaOptions: { name: allMarketName }
            },
            {
                trackingId: GACode,
                gaOptions: { name: eachMarket }
            }
        ]
        ReactGA.initialize(trackers)
        ReactGA.pageview(label, [allMarketName, eachMarket])
        ReactGA.event(
            {
                category: category,
                action: action,
                label: label
            },
            [allMarketName, eachMarket]
        )
    }

    Commit(fullCountryName) {
        let GACode = 'UA-205115347-1'
        let GACodeAll = 'UA-205115347-2'

        const allMarketName = 'UshopLink'
        const trackers = [
            {
                trackingId: GACodeAll,
                gaOptions: { name: allMarketName }
            }
        ]
        let eventType = window.location.pathname
        let tracks = [allMarketName]
        
        if (/splash/.test(eventType)) {
            tracks.push(fullCountryName)
            trackers.push({
                trackingId: GACode,
                gaOptions: { name: fullCountryName }
            })
        }
        console.log(trackers, tracks)
        ReactGA.initialize(trackers)
        ReactGA.pageview(window.location.pathname, tracks)
    }

    get CATEGORY() {
        return {
            PAGE_VIEW: 'Page View',
            EXTERNAL_LINK: 'External Link'
        }
    }

    get ACTION() {
        return {
            CLICK: 'click',
            VIEW: 'view',
            MOBILE_CLICK: 'click via mobile',
            MOBILE_VIEW: 'view on mobile'
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new TrackingEvents()
