import { makeAutoObservable } from 'mobx';

class StoreIdVerification{

    currentStep = 1
    step1 = false
    step2 = false
    step3 = false
    step4 = false
    data = {
        reference_id: null,
        ba_id: null,
        government_id: null,
        mobile: null,
        front_id_img: null,
        selfie_id_img: null,
        bank_name: null,
        bank_id: null,
        bank_account_number: null,
        bank_account_name: null,
        bank_account_type: null,
        bank_choice: null,
        bank_img: null
    }
    isVerified = false
    banks = []
    success_reference_id = null
    approval_status = null
    is_submit = false
    bankSelected = null

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeIdVerification = new StoreIdVerification()