import { dictionary } from '@Components/GlobalHelpers'
import { logn } from '@Components/utils/PikaLog'
import { makeAutoObservable } from 'mobx'
import { store } from './MainStore'
import { storeAutoShip } from './StoreAutoShip'
import { Country } from './StoreConfigCountry'
import { storeRenew } from './StoreRenew'

class StoreHeader {
    get EnrollTitle() {
        return {
            SelectStarterKit: 'u_all_enroll_title_select_starter_kit',
            AddDetail: 'u_all_enroll_enroll_detail',
            Summary: 'u_all_enroll_ordersummary',
            Summary2: 'u_all_summary',
            Resume: 'u_all_enroll_payment_resume_title',
            Result: 'u_all_enroll_payment_result'
        }
    }

    get AutoshipTitle() {
        return {
            Summary: 'autoship_summary',
            Address: 'autoship_address',
            Edit: 'u_all_autoship_edit_autoship',
            Add: 'u_all_autoship_add_autoship'
        }
    }

    get RenewalTitle() {
        return {
            SelectRenewal: 'u_all_renewal_title_select_renewal',
            OrderDetail: 'u_all_od_renew_title',
            OrderSummary: 'u_all_os_renew_title'
        }
    }

    get CustomLink() {
        return {
            Result: 'payment_result'
        }
    }

    get PaymentResponse() {
        return {
            Summary: 'summary'
        }
    }

    get OrderDetail() {
        return {
            Title: 'u_all_od_title'
        }
    }

    get OnlineShop() {
        return {
            Title: 'online_shop'
        }
    }

    get IdVerification() {
        return {
            Title: 'u_all_idverification_identity_verification'
        }
    }

    get SocialShop() {
        return {
            Result: 'u_all_social_payment_result'
        }
    }

    checkPath(pathname) {
        if (/\/enroll\/$|\/enroll\?/.test(pathname) || /\/enroll$|\/enroll\?/.test(pathname)) {
            return Country.isThailand() ? '' : this.EnrollTitle.SelectStarterKit
        } else if (/\enroll\/detail/.test(pathname)) {
            return this.EnrollTitle.AddDetail
        } else if (/\enroll\/ordersummary/.test(pathname)) {
            if (/\/cambodia\/gi\/ordersummary/.test(pathname)) {
                return this.EnrollTitle.Summary2
            } else {
                return this.EnrollTitle.Summary
            }
        } else if (/\enroll\/payment-resume/.test(pathname)) {
            return this.EnrollTitle.Resume
        } else if (/\payment-resume/.test(pathname)) {
            return this.EnrollTitle.Resume
        } else if (/\enroll\/payment-response/.test(pathname)) {
            return this.EnrollTitle.Result
        } else if (/\/social\/payment-response/.test(pathname)) {
            return this.SocialShop.Result
        } else if (!/payment-response/.test(pathname) && !/ordersummary/.test(pathname) && !/social/.test(pathname) && !/renewal/.test(pathname) && !store.shareCart.proceedCheckout && !/autoship/.test(pathname) && !/enroll/.test(pathname)) {
            return this.OnlineShop.Title
        } else if (!/payment-response/.test(pathname) && !/ordersummary/.test(pathname) && !/social/.test(pathname) && !/renewal/.test(pathname) && store.shareCart.OrderDetailForm && !/enroll/.test(pathname)) {
            return this.OrderDetail.Title
        } else if (!/payment-response/.test(pathname) && !/renewal/.test(pathname) && (/ordersummary/.test(pathname) || /social/.test(pathname)) && !/autoship\/ordersummary/.test(pathname) && !/enroll/.test(pathname)) {
            return this.PaymentResponse.Summary
        } else if (/\social\/payment-response\/success/.test(pathname)) {
            console.log('test')
            return this.SocialShop.Result
        } else if (/Page=customlink/.test(window.location.href) || /customlink\/checkout\/payment-response/.test(window.location.href) || /renewal\/payment-response/.test(pathname)) {
            return this.CustomLink.Result
        } else if (/renewal/.test(pathname) && storeRenew.proceedOrderSummary) {
            return this.RenewalTitle.OrderSummary
        } else if (/renewal/.test(pathname) && storeRenew.proceedCheckout && !storeRenew.proceedOrderSummary) {
            return this.RenewalTitle.OrderDetail
        } else if (/renewal/.test(pathname) && !/renewal\/payment-response/.test(pathname) && !storeRenew.proceedCheckout && !storeRenew.proceedOrderSummary) {
            return this.RenewalTitle.SelectRenewal
        } else if (/autoship/.test(window.location.pathname) && /edit/.test(window.location.pathname) === false && !storeAutoShip.proceedCheckout && storeAutoShip.PAGE_INDEX === 1) {
            return this.AutoshipTitle.Add
        } else if (/autoship/.test(window.location.pathname) && /edit/.test(window.location.pathname) && !storeAutoShip.proceedCheckout && storeAutoShip.PAGE_INDEX === 1) {
            return this.AutoshipTitle.Edit
        } else if (/autoship/.test(pathname) && !storeAutoShip.proceedCheckout && storeAutoShip.PAGE_INDEX === 2) {
            return this.AutoshipTitle.Address
        } else if (/autoship/.test(pathname) && storeAutoShip.proceedCheckout) {
            return this.AutoshipTitle.Summary
        } else if (/id-verification/.test(pathname)) {
            return this.IdVerification.Title
        } else {
            return this.CustomLink.Result
        }
    }

    setHeaderTitle(location) {
        this.Title = this.checkPath(location.pathname)
    }

    Title = ''

    constructor() {
        this.setHeaderTitle(window.location)
        makeAutoObservable(this)
    }
}

export default new StoreHeader()
