import Raven from '../Raven';
import { CountryConfigs } from '../../components/configs/Configs';
import $ from 'jquery';
import { isNothing, isSomething, isFunction, isString, isArray, isJSON } from '../../components/utils/Utils';

const urlHost = /localhost/.test(window.location.href) || /ushop-dev1/.test(window.location.href) ? 'https://member-calls2.unicity.com' : 'https://member-calls2.unicity.com'

export const _get = (url, params, configs, fulfilledInterceptor, rejectInterceptor) => {


    const options = {
        method: 'GET',
        url: url,
        async: true,
        ...configs
    }

    if (isSomething(params)) {
        options.data = params
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) fulfilledInterceptor(response)
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }

                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}
export const getProductSource2 = (allow, price_level, customerID) => {

    if (isNothing(allow)) {
        allow = 'shop'
    }

    const url = `${urlHost}/products-v2/publish/${CountryConfigs.CountryCode3().toLowerCase()}`
    const params = {
        allow: allow,
        status: price_level
    }

    if (allow === 'social' && customerID) {
        params.baId = customerID
    }

    const result = _get(
        url,
        params
    )
    return result

}

// export const getProductSource = (isHydra, priceLevel, warehouse) => {

//     const url = 'https://member-calls.unicity.com/api/unishop/v1/global/getproducts'
//     const params = {
//         country_code: CountryConfigs.CountryCode3()
//     }

//     if (warehouse) {
//         params.warehouse = warehouse
//     }

//     if (isHydra === false) {
//         params.secret = 'yes'
//         params.price_level = priceLevel
//     }

//     return (
//         Raven.get(
//             url,
//             params
//         )
//     )
// }