import { observable, makeAutoObservable, get as mget, set as mset } from 'mobx';


class StoreRenew {
    cart = []
    productSource = []
    productRenewSource = []
    selectedItems = []
    checkoutCart = []
    DeleteID = ''
    renewItems = []
    renewID = ''
    renewItemSelected = ''
    proceedCheckout = false
    proceedOrderSummary = false
    openDeleteModel = false
    orderError = false
    errorMessage = []
    checkoutAddress = {}
    total = 0
    totalPv = 0
    priceLevel = 2
    priceLevelString = 'wholesale'
    hasDiscontinuedProduct = false
    showAlert = false
    calculateOrderData = ''
    calculateOrder = ''
    errors = ''
    baDetails = {}
    baId = ''
    productLoad = false
    checkEnabledAddProduct = false
    isOpenSelectProductModal = false

    openSelectProductModal() {
        this.isOpenSelectProductModal = true
    }

    resetSelectProductModal() {
        this.isOpenSelectProductModal = false
    }
    
    constructor() {
        makeAutoObservable(this)
    }
}

export const storeRenew = new StoreRenew()