import { makeAutoObservable } from 'mobx';
class StoreShopProfile {
    cart = []
    total = 0
    totalPv = 0
    priceLevel = 1
    priceLevelString = 'retail'
    hasDiscontinuedProduct = false
    showAlert = false
    errors = []
    constructor() {
        makeAutoObservable(this)
    }
}

export const storeShopProfile = new StoreShopProfile()